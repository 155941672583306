import React from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import UserSettings from "../../common/dialogs/userSettings";

function logout() {
  localStorage.removeItem("isLogIn");
  localStorage.removeItem("type");


  window.location.assign("/");
}

const menu = (
  <Menu key="pharmacyStore" className="usermenu">
    <span className="user-info dropdown-item bg-none" href="#">
      <h1>Welcome !</h1>
      <p>Username</p>
    </span>
    <UserSettings />
    <Link to="#" className="dropdown-item" href="#">
      <i className="mdi mdi-headset"></i> Support
    </Link>
    <Link to="#" className="dropdown-item logout" onClick={() => logout()}>
      <i className="mdi mdi-logout"></i> Logout
    </Link>
  </Menu>
);
class UserControlles extends React.Component {
  render() {
    return (
      <>
        <div className="user-controlles">
          <Dropdown className="ml-3" overlay={menu} placement="bottomRight" arrow>
            <span className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <UserOutlined />
            </span>
          </Dropdown>
        </div>
      </>
    );
  }
}
export default UserControlles;
