import React from "react";
import { InputNumber } from "antd";
import { amountFormatter, amountParser } from "../../../../utils/methods/universal";
const InsBillingDetail = ({ title }) => {
  const [current, setCurrent] = React.useState(0);
  const onChange = async (current) => {
    console.log("onChange:", current);
    setCurrent(current);
  };
  return (
    <>
      <section className="custom-table mx-0 border-0 mb-0">
        <div className="section-title mb-2">
          <span className="title-text">{title}</span>
          <div className="detail-group d-inline">
            <div className="detail-block float-left mt-2">
              <div className="detail-info">
                <label>BIN #</label>
                <span>NA</span>
              </div>
              <div className="detail-info">
                <label>PBM</label>
                <span>NA</span>
              </div>
            </div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th width="100">Ing.</th>
              <th width="100">
                <span>Dispensing</span>Fee
              </th>
              <th width="100">
                <span>Other</span> Fee
              </th>
              <th width="100">
                <span>Sale</span> Tax
              </th>
              <th width="100">
                <span>Other</span> Amount
              </th>
              <th width="100">Total</th>
              <th width="100">
                <span>Insurance</span> Approved
              </th>
              <th width="110">
                <span>Insurance</span> Paid
              </th>
              <th width="100">Copay</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="arrow-label addon right float-left text-white"> Billing</span>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl w-100"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl w-100"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl w-100"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl w-100"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span className="arrow-label addon right float-left text-white width-88"> Paid</span>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl w-100"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl w-100"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl w-100"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl w-100"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl w-100"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
              <td></td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl w-100"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl w-100"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
              <td>
                <div className="input-group input-amount">
                  <InputNumber
                    disabled
                    className="formControl w-100"
                    placeholder="0.00"
                    min={0}
                    formatter={(value) => amountFormatter(value)}
                    parser={(value) => amountParser(value)}
                    precision={2}
                    onChange={onChange}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  );
};

export default InsBillingDetail;
