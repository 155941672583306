import React, { useState } from "react";
import { Checkbox, DatePicker, Input, Select, Tooltip } from "antd";
import ContactInfo from "../../../../components/common/contactInfo";
import { MaskedInput } from "antd-mask-input";

export default function WorksmanCom() {
  const [state, setState] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    cellPhone: "",
    phone: "",
    workPhone: "",
    email: "",
    fax: "",
    cityId: 0,
    isAddress: false,
  });
  async function handleChange(evt) {
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
      willCall: evt.target.value,
      deliveryHandler: evt.target.value,
    });
  }
  return (
    <>
      <section className="bg-secondary m-0">
        <div className="input-group input-date">
          <DatePicker className="formControl" placeholder="MM/DD/YYYY" format="MM/DD/YYYY" />
          <Tooltip title="Date of Injury">
            <label className="formLabel">DOI</label>
          </Tooltip>
        </div>
        <div className="input-group input-xl float-right">
          <Input className="formControl" placeholder="Nature of Injury" />
          <label className="formLabel">Nature of Injury</label>
        </div>
      </section>
      <section className="m-0">
        <div className="input-group input-lg">
          <Input className="formControl" placeholder="Carrier #" />
          <label className="formLabel">Carrier #</label>
        </div>
        <div className="input-group input-xl float-right">
          <Input className="formControl" placeholder="Carrier Name" />
          <label className="formLabel">Carrier Name</label>
        </div>
      </section>
      <section className="contact-info border-0 mb-0 mx-0">
        <ContactInfo state={state} type={"worksmanCom"} handleChange={handleChange} />
        <div className="d-inline-block w-100 mt-2">
          <div className="input-group input-phone">
            <MaskedInput
              id="wcOneWorkTel"
              className="formControl"
              placeholder="Work Tel"
              mask="111 111 1111"
              name="wcOneWorkTel"
              prefix={
                <Tooltip title="Work Tel" position="top">
                  <i className="mdi mdi-phone"></i>
                </Tooltip>
              }
            />
          </div>
          <div className="input-group input-phoneExt">
            <MaskedInput id="wcOneWorkTelExt" className="formControl" placeholder="Ext." mask="11111" name="wcOneWorkTelExt" />
          </div>
          <div className="input-group input-phone">
            <MaskedInput
              id="wcOneFax"
              className="formControl"
              placeholder="Fax"
              mask="111 111 1111"
              name="fax"
              prefix={
                <Tooltip title="Fax" position="top">
                  <i className="mdi mdi-fax"></i>
                </Tooltip>
              }
            />
          </div>
        </div>
      </section>
      <section className="mb-0 pb-0">
        <div className="section-title">
          <span className="title-text">Employer Info.</span>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="input-group input-lg">
              <Input className="formControl" placeholder="Claim #" />
              <label className="formLabel">Claim #</label>
            </div>
          </div>
          <div className="col-12">
            <div className="input-group input-xl">
              <Input className="formControl" placeholder="Contact Name" />
              <label className="formLabel">Contact Name</label>
            </div>
            <div className="input-group input-xl">
              <Input className="formControl" placeholder="Employer Name" />
              <label className="formLabel">Employer Name</label>
            </div>
          </div>
        </div>
        <div className="row">
          <section className="contact-info border-0 mb-0 mx-0">
            <ContactInfo state={state} type={"worksmanComEmployer"} handleChange={handleChange} />
            <div className="d-inline-block w-100 mt-2">
              <div className="input-group input-phone">
                <MaskedInput
                  id="wcOneWorkTel"
                  className="formControl"
                  placeholder="Work Tel"
                  mask="111 111 1111"
                  name="wcOneWorkTel"
                  prefix={
                    <Tooltip title="Work Tel" position="top">
                      <i className="mdi mdi-phone"></i>
                    </Tooltip>
                  }
                />
              </div>
              <div className="input-group input-phoneExt">
                <MaskedInput id="wcOneWorkTelExt" className="formControl" placeholder="Ext." mask="11111" name="wcOneWorkTelExt" />
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}
