import React, { Suspense, useState } from "react";
import { Modal, Button, Popconfirm, message, Input, Select, Checkbox, Tooltip, InputNumber, Radio } from "antd";
import { MaskedInput } from "antd-mask-input";
import SetStatus from "Components/common/setStatus";
import { prescriberClinicDetailState } from "../../../utils/constant/prescriber/atom";
import { useRecoilState } from "recoil";
import Address from "./components/address";
import SectionLoader from "../../../components/common/loader/section";

function PrescriberClinicDetail() {
  const [checked, setChecked] = useState(false);
  const [isPrescriberClinicDetail, setPrescriberClinicDetail] = useRecoilState(prescriberClinicDetailState);

  const handleOk = async (e) => {
    setPrescriberClinicDetail(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setPrescriberClinicDetail(false);
    message.error("Data not saved!");
  };

  const handleChange = async (value) => {
    console.log(`selected ${value}`);
  };

  function handleShowMsg(e) {
    if (e.target.checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }

  const { Option } = Select;

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  }

  return (
    <>
      <Suspense fallback={<SectionLoader />}>
        <Button className="btn float-right" icon={<i className="mdi mdi-plus-circle" />} onClick={() => setPrescriberClinicDetail(true)}>
          Clinic
        </Button>
        <Modal
          width={550}
          title="Add Clinic"
          visible={isPrescriberClinicDetail}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          maskClosable={false}
          // keyboard={false}
          footer={
            <div className="btn-bar">
              <div className="float-right">
                <Button className="btn btn-save" onClick={handleOk}>
                  <span></span>
                </Button>
                <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                  <Button className="btn btn-close">
                    <span></span>
                  </Button>
                </Popconfirm>
              </div>
            </div>
          }>
          <div className="row">
            <div className="col-12">
              <section className="bg-primary">
                <div className="input-group input-lg">
                  {/* <MaskedInput id="ssId" className="formControl" placeholder="Surescripts ID" mask="1111 111 1111" name="ssId" /> */}
                  <Input className="formControl" placeholder="Surescripts ID" max="20" />
                  <label className="formLabel">Surescripts ID</label>
                </div>
                <div className="input-group width-80">
                  <Input className="formControl" placeholder="Clinic Name" autoFocus />
                  <label className="formLabel">Clinic Name</label>
                </div>
                <div className="input-group input-switch color-green w-auto float-right">
                  <SetStatus label="Status" />
                </div>

                <section className="bg-secondary">
                  <div className="input-group input-phone with-addon">
                    <div className="input-group-prepend spacer">
                      <i className="mdi mdi-numeric-1-box"></i>
                    </div>
                    <MaskedInput
                      id="tel1"
                      className="formControl"
                      placeholder="Tel"
                      mask="111 111 1111"
                      name="tel1"
                      prefix={
                        <Tooltip title="Tel" position="top">
                          <i className="mdi mdi-phone"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="input-group input-phone">
                    <MaskedInput
                      id="fax1"
                      className="formControl"
                      placeholder="Fax"
                      mask="111 111 1111"
                      name="fax1"
                      prefix={
                        <Tooltip title="Fax" position="top">
                          <i className="mdi mdi-fax"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="input-group input-phone with-addon">
                    <div className="input-group-prepend spacer">
                      <i className="mdi mdi-numeric-2-box"></i>
                    </div>
                    <MaskedInput
                      id="tel3"
                      className="formControl"
                      placeholder="Tel"
                      mask="111 111 1111"
                      name="tel3"
                      prefix={
                        <Tooltip title="Tel" position="top">
                          <i className="mdi mdi-phone"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="input-group input-phone">
                    <MaskedInput
                      id="fax2"
                      className="formControl"
                      placeholder="Fax"
                      mask="111 111 1111"
                      name="fax2"
                      prefix={
                        <Tooltip title="Fax" position="top">
                          <i className="mdi mdi-fax"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                </section>
                <section className="contact-info border-0 m-0 p-0">
                  <Address />
                </section>
              </section>
            </div>
            {/* <div className="col-lg-4">
              <section className="bg-secondary">
               
                <hr></hr>
                <div className="input-group input-group-radio radio-block">
                  <Radio.Group defaultValue={1}>
                    <Radio value={1}>Fax</Radio>
                    <Radio value={2}>eRx</Radio>
                    <Radio value={3}>Both</Radio>
                    <Radio value={4}>None</Radio>
                  </Radio.Group>
                </div>
              </section>
            </div> */}
          </div>
        </Modal>
      </Suspense>
    </>
  );
}

export default PrescriberClinicDetail;
