import React from "react";
import { Input, Tooltip, Table } from "antd";

const drugPriceCodesData = [];
const columnsDrugPriceCodes = [
  {
    title: `Name`,
    dataIndex: "name",
    key: "name",
    width: 200,
    ellipsis: {
      showTitle: false,
    },
    render: (name) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={name}>
        {name}
      </Tooltip>
    ),
  },
  {
    title: "NDC",
    dataIndex: "ndc",
    key: "ndc",
    width: 100,
  },
  {
    title: `Pkg. Size`,
    dataIndex: "pkgSize",
    key: "pkgSize",
    width: 100,
  },
  {
    title: `RT PG #`,
    dataIndex: "rtpgNumber",
    key: "rtpgNumber",
    width: 100,
  },
  {
    title: "RxN #",
    dataIndex: "rxnNumber",
    key: "rxnNumber",
    width: 100,
  },
  {
    title: "RxN PG#",
    dataIndex: "rxnpgNumber",
    key: "upc",
    width: 100,
  },
];

export default function PatientPriceCodes() {
  return (
    <>
      <section className="bg-primary">
        <div className="section-title">
          <label className="title-text">Price Codes</label>
        </div>
        <div className="input-group input-name">
          <Input className="formControl" placeholder="Drug Name" />
          <label className="formLabel">Drug Name</label>
        </div>
        <div className="input-group input-stg">
          <Input className="formControl" placeholder="Strength" />
          <label className="formLabel">Strength</label>
        </div>
        <div className="input-group input-sm">
          <Input className="formControl" placeholder="RT PG #" />
          <label className="formLabel">RT PG #</label>
        </div>
        <div className="input-group input-md">
          <Input className="formControl" placeholder="Price Code" />
          <label className="formLabel">Price Code</label>
        </div>
        <Table
          className="table-length length-five"
          size="small"
          columns={columnsDrugPriceCodes}
          dataSource={drugPriceCodesData}
          pagination={false}
          scroll={{ y: 150 }}
          rowKey={(record) => record.uid}
        />
      </section>
    </>
  );
}
