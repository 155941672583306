import React, { useState } from "react";
import { Modal, Button, Tooltip, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { amountFormatter, amountParser } from "../../../../utils/methods/universal";
import { cvsInfoState } from "../../../../utils/constant/processing/atom";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import moment from "moment";

export default function CvsInfo() {
  const [current, setCurrent] = React.useState(0);
  const [isCvsInfo, setCvsInfo] = useRecoilState(cvsInfoState);

  const cvsInfoModal = () => {
    setCvsInfo(true);
  };

  const handleOk = async (e) => {
    setCvsInfo(false);
  };

  const handleCancel = async (e) => {
    setCvsInfo(false);
  };

  const onChange = async (current) => {
    console.log("onChange:", current);
    setCurrent(current);
  };

  const { Option } = Select;

  return (
    <>
      <Modal
        width={500}
        className="modal-cvs-info"
        title="CVS Info"
        visible={isCvsInfo}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <section className="m-0 p-0">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <section className="info">
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>TEI</label>
                    </Tooltip>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>BORD</label>
                    </Tooltip>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>ADA</label>
                    </Tooltip>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>RDA</label>
                    </Tooltip>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>BSC</label>
                    </Tooltip>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>BSQ</label>
                    </Tooltip>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>BSA</label>
                    </Tooltip>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>DUR/PPS RCC</label>
                    </Tooltip>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>RFSC</label>
                    </Tooltip>
                    <span>30</span>
                  </div>
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>OPI</label>
                    </Tooltip>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>DI</label>
                    </Tooltip>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>OPI</label>
                    </Tooltip>
                    <span>0</span>
                  </div>
                  <div className="info-block">
                    <Tooltip title="Tooltip text" placement="top">
                      <label>DFT</label>
                    </Tooltip>
                    <span>NA</span>
                  </div>
                </section>
                <section className="info">
                  <div className="section-title">
                    <span className="title-text">Other Payer Info.</span>
                  </div>
                  <div className="info-block">
                    <label>ID Count</label>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <label>Coverage Type</label>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <label>ID Qualifier</label>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <label>ID</label>
                    <span>NA</span>
                  </div>
                  <div className="info-block width-45">
                    <label>Processor Control Number</label>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <label>CH ID</label>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <label>Group ID</label>
                    <span>NA</span>
                  </div>
                  <div className="info-block">
                    <label>Person Code</label>
                    <span>NA</span>
                  </div>
                  <div className="info-block width-30">
                    <label>Help Desk Phone #</label>
                    <span>NA</span>
                  </div>
                  <div className="info-block width-40">
                    <label>Patient Relationship Code</label>
                    <span>NA</span>
                  </div>
                  <div className="info-block  width-30">
                    <label>Benefit Eff. Date</label>
                    <span>NA</span>
                  </div>
                  <div className="info-block  width-30">
                    <label>Benefit Term. Date</label>
                    <span>NA</span>
                  </div>
                </section>
                <section className="info">
                  <div className="section-title">
                    <span className="title-text">Support</span>
                  </div>
                  <div className="info-block width-45">
                    <label>7f. ?</label>
                    <span>NA</span>
                  </div>
                  <div className="info-block width-45">
                    <label>8f. ?</label>
                    <span>NA</span>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </>
      </Modal>
    </>
  );
}
