// Company Registration
let domain = "https://dev-ec2.ikonrx.com";
let serviceDomain = "https://dev-ec2.ikonrx.com";
let permissionDomain = "https://dev-ec2.ikonrx.com";

// Company

export const getCompanyRegistration = `${domain}/api/permissions/load-permissions`;

export const storeCompanyRegistration = `${domain}/api/company-service/company/save`;
export const companyDelete = `${domain}/api/company-service/company/delete`;

export const storeCompanySearch = `${domain}/api/company-service/company/searchCompany`;

export function getCompany(id) {
  return `${domain}/api/company-service/company/${id}`;
}
export function updateCompany(id) {
  return `${domain}/api/company-service/company/${id}`;
}




// Helper Api

export const allState = `${serviceDomain}/api/shared-service/state/states_list`;

export const searchCity = `${serviceDomain}/api/shared-service/city/search_city`;

export const searchState = `${serviceDomain}/api/shared-service/state/search_state`;

export function getCityByZip(id) {

  return `${serviceDomain}/api/shared-service/zip/state_city_by_zip_code?zipCode=${id}`;
}


export const getStore = `${domain}/store`;
export const storeSearch = `${domain}/api/store-service/store/search`;
export const saveStore = `${domain}/api/store-service/store/save`;
export const updateStore = `${domain}/api/store-service/store/update`;
export const changeStoreStatus = `${domain}/api/store-service/store/change-status`;

export function getStoreByID(id) {

  return `${domain}/api/store-service/store/getStore/${id}`;
}


// Employee

export const empSearch = `${domain}/api/employee-service/employee/search`;
export const empSave = `${domain}/api/employee-service/employee/save`;
export const empUpdate = `${domain}/api/employee-service/employee/update`;
export function editEmployee(id) {
  return `${domain}/api/employee-service/employee/${id}`;
}


// Role
export const storeRole = `${permissionDomain}/api/roles-service/roles/save`;
export const updateRole = `${permissionDomain}/api/roles-service/roles/update`;

export function searchRole(id) {
  return `${permissionDomain}/api/roles-service/roles/search/${id}`;
}

export function editRole(id) {
  return `${permissionDomain}/api/roles-service/roles/get-role/${id}`;
}

export function deleteRole(roleID, loginID) {
  return `${permissionDomain}/api/roles-service/roles/delete-role/${roleID}/${loginID}`;
}



// Permission

export const getAllPermission = `${permissionDomain}/api/roles-service/permissions/load-permissions`;



export const loginRoute = `${domain}/oauth/token`;






