import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { Space, Table, Tooltip, message, Tag, Button, Modal, DatePicker, Input, Checkbox, Select } from "antd";
import moment from "moment";
import { useRecoilState } from "recoil";
import { dmsSearchState } from "../../../../utils/constant/notification/atom";
import DocumentList from "../../dms/components/docList";
import DocumentPreview from "../../dms/components/docView";
import { SearchOutlined } from "@ant-design/icons";
function confirm(e) {
  console.log(e);
  message.success("Click on Yes");
}

function cancel(e) {
  message.error("Click on No");
}

export default function DmsSearch() {
  const [isDmsSearch, setDmsSearch] = useRecoilState(dmsSearchState);

  const dmsModal = () => {
    setDmsSearch(true);
  };

  const handleOk = async (e) => {
    setDmsSearch(false);
  };

  const handleCancel = async (e) => {
    setDmsSearch(false);
  };
  const { Option } = Select;
  return (
    <Modal
      width={1300}
      style={{ top: 25 }}
      className="modal-dms"
      title="DMS Search"
      visible={isDmsSearch}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      maskClosable={false}
      // keyboard={false}
      footer={
        <div className="btn-bar">
          <div className="float-right">
            <Button className="btn btn-close" onClick={handleCancel}>
              <span></span>
            </Button>
          </div>
        </div>
      }>
      <>
        <section className="filter-bar">
          <form>
            <section className="bg-secondary border-0 mb-0">
              <div className="input-group input-lg">
                <Input className="formControl" placeholder="" autoFocus />
                <label className="formLabel">Search Name</label>
              </div>
              <div className="input-group input-lg">
                <Select className="formControl" placeholder="Search Type" defaultValue="1">
                  <Option value="1">Patient Docs</Option>
                  <Option value="2">Insurance Card</Option>
                  <Option value="3">Lab Reports</Option>
                  <Option value="4">Delivery Slips</Option>
                </Select>
                <label className="formLabel">Search Type</label>
              </div>
              <div className="input-group input-date">
                <DatePicker className="formControl" format="MM/DD/YYYY" placeholder="MM/DD/YYYY" />
                <Tooltip title="Upload Date">
                  <label className="formLabel">Upload Date</label>
                </Tooltip>
              </div>
              <div className="filter-actions">
                <Tooltip title="Search" mouseLeaveDelay={0}>
                  <Button className="btn btn-sm btn-search" icon={<SearchOutlined />} />
                </Tooltip>
                <Button className="btn btn-clear-all">Clear All</Button>
              </div>
              {/* <div className="d-flex justify-content-end">
              <Button className="btn btn-scan">Scan</Button>
              <Button className="btn btn-upload float-right">
                <i className="mdi mdi-upload"></i>
                <span>Upload</span>
                <input type="file" />
              </Button>
              <Button className="btn btn-rx">New</Button>
              <Button className="btn btn-save">
                <span></span>
              </Button>
              <Button className="btn btn-delete">Delete</Button>
              <Button className="btn btn-fax">Send Fax</Button>
            </div> */}
            </section>
          </form>
          <div className="document-view mt-2">
            <DocumentList />
            <section className="document-preview-panel ml-5 my-0 p-0">
              <div className="action-btns p-0">
                <Tooltip title="Scan" placement="right">
                  <Button className="btn btn-icon btn-scan">
                    <span></span>
                  </Button>
                </Tooltip>
                <Tooltip title="Upload" placement="right">
                  <span className="btn btn-icon btn-upload float-right">
                    <i></i>
                    <input type="file" />
                  </span>
                </Tooltip>
                <Tooltip title="New Rx" placement="right">
                  <Button className="btn btn-icon btn-rx">
                    <span></span>
                  </Button>
                </Tooltip>
                <Tooltip title="Save" placement="right">
                  <Button className="btn btn-icon btn-save">
                    <span></span>
                  </Button>
                </Tooltip>
                <Tooltip title="Delete" placement="right">
                  <Button className="btn btn-icon btn-delete">
                    <span></span>
                  </Button>
                </Tooltip>
                <Tooltip title="Send Fax" placement="right">
                  <Button className="btn btn-icon btn-fax">
                    <span></span>
                  </Button>
                </Tooltip>
              </div>
              <DocumentPreview />
            </section>
          </div>
        </section>
      </>
    </Modal>
  );
}
