import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Input, Radio, Select, Space, Switch, Tooltip } from "antd";
import ContactInfo from "Components/common/contactInfo";
import SetStatus from "Components/common/setStatus";
import { MaskedInput } from "antd-mask-input";
import DeliveryAddress from "./addressDelivery";
import FamilyMembers from "./familyMembers";
import { familyMemberState } from "../../../../utils/constant/patient/atom";
import { useRecoilState } from "recoil";
import moment from "moment";
import { dateFormate, dateParser, disabledAfterCurr } from "../../../../utils/methods/validation";
import { statusState } from "../../../../utils/constant/atom";
import FamilyMemberTable from "./familyMemberTable";

export default function PatientInfo() {
  const { Option } = Select;
  const [isFamilyMember, setFamilyMember] = useRecoilState(familyMemberState);
  const [isStatusState, setStatusState] = useRecoilState(statusState);
  const [state, setState] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    isWorkTel: true,
    isAddress: true,
    isPatient: true,
    cityId: 0,
    businessPhone: "",
    cellPhone: "",
    email: "",
    fax: "",
    deliveryHandler: 0,
    willCall: 0,
    dob: "",
    insdob: "",
    insDobDis: false
  });
  async function handleChange(evt) {
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
      willCall: evt.target.value,
      deliveryHandler: evt.target.value,
    });
  }

  function currentTime() {
    var current = new Date();
    var followingDay = new Date(current.getTime() + 86400000);
    followingDay.toLocaleDateString();
    console.log(followingDay);

    return new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) + " " + moment(followingDay).format("MM/DD/YYYY");
  }

  return (
    <>
      <div className="d-none">
        <FamilyMembers />
      </div>
      <form>
        <section className="bg-primary pat-info">
          <div className="row flex-nowrap w-100 pr-4">
            <div className="col-lg-7 py-2">
              <div className="row">
                <div className="col-12">
                  <div className="input-group input-md">
                    <Input className="formControl" placeholder="Patient #" disabled />
                    <label className="formLabel">Patient #</label>
                  </div>
                  <div className="input-group input-switch color-green w-auto float-right">
                    <SetStatus defaultChecked label="Status" state={state} setState={setState} onClick={() => setStatusState(true)} tabindex="-1" />
                  </div>
                </div>
                <section className="px-0 py-2 bg-secondary">
                  <div className="d-flex flex-wrap">
                    <div className="col-12">
                      {/* <div className="input-group width-10">
                      <Select className="formControl" defaultValue="mr">
                      <Option value="mr">Mr.</Option>
                      <Option value="ms">Ms.</Option>
                      </Select>
                      <label className="formLabel">Title</label>
                    </div> */}
                      <div className="input-group input-name">
                        <Input className="formControl" placeholder="Last Name" autoFocus />
                        <label className="formLabel">Last Name</label>
                      </div>
                      <div className="input-group input-name">
                        <Input className="formControl" placeholder="First Name" />
                        <label className="formLabel">First Name</label>
                      </div>
                      <div className="input-group input-name">
                        <Input className="formControl" placeholder="MI" />
                        <label className="formLabel">MI</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group input-date">
                        {state.dob ? <DatePicker
                          className="formControl"
                          placeholder="MM/DD/YYYY"
                          format="MM/DD/YYYY"
                          name="dateOfBirth"
                          value={moment(state.dob, "MM/DD/YYYY")}
                          onChange={(date, dateString) => {
                            setState({
                              ...state,
                              dob: date,
                              insdob: date
                            })
                          }}
                        // disabled={disabledAfterCurr}
                        // format={dateFormate}
                        // dateParser={(value) => dateParser(value)}
                        /> : <DatePicker
                          className="formControl"
                          placeholder="MM/DD/YYYY"
                          format="MM/DD/YYYY"
                          name="dateOfBirth"
                          onChange={(date, dateString) => {
                            setState({
                              ...state,
                              dob: date,
                              insdob: date
                            })
                          }}
                        // disabled={disabledAfterCurr}
                        // format={dateFormate}
                        // dateParser={(value) => dateParser(value)}
                        />}
                        <Tooltip title="Date of Birth">
                          <label className="formLabel">DOB</label>
                        </Tooltip>
                      </div>
                      <div className="input-group w-auto">
                        <hr axis="y" className="input-divider mx-4"></hr>
                      </div>
                      <div className="input-group input-date with-addon ml-0 mr-4">
                        <div className="input-group-prepend">
                          <Checkbox for="insDob" checked={state.insDobDis} onChange={(e) => setState({ ...state, insDobDis: e.target.checked })}></Checkbox>
                        </div>
                        {state.insdob ? <DatePicker id="insDob" placeholder="MM/DD/YYYY" value={moment(state.insdob, "MM/DD/YYYY")} onChange={(date, dateString) => {
                          setState({
                            ...state,

                            insdob: date
                          })
                        }} format="MM/DD/YYYY" className="formControl" disabled={state.insDobDis === true ? false : true} /> : <DatePicker id="insDob" placeholder="MM/DD/YYYY" onChange={(date, dateString) => {
                          setState({
                            ...state,

                            insdob: date
                          })
                        }} format="MM/DD/YYYY" className="formControl" disabled={state.insDobDis === true ? false : true} />}
                        <Tooltip title="Insurance Date of Birth">
                          <label className="formLabel">INS. DOB</label>
                        </Tooltip>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div className="input-group input-md">
                          <Select className="formControl" defaultValue="human">
                            <Option value="human">Human</Option>
                            <Option value="animal">Animal</Option>
                          </Select>
                          <label className="formLabel">Kind</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group input-group-radio w-auto">
                        <Radio.Group defaultValue={1}>
                          <span className="radio-label">Gender</span>
                          <Radio value={1}>M</Radio>
                          <Radio value={2}>F</Radio>
                          <Radio value={3}>U</Radio>
                        </Radio.Group>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div className="input-group input-switch w-auto">
                          <Switch
                            size="small"
                            className="formControl bg-green"
                            checkedChildren={<i className="mdi mdi-check" />}
                            unCheckedChildren={<i className="mdi mdi-close" />}
                            defaultChecked
                          />
                          <label className="formLabel">Autofill</label>
                        </div>
                        <div className="input-group input-switch w-auto">
                          <Switch
                            size="small"
                            className="formControl bg-red"
                            checkedChildren={<i className="mdi mdi-check" />}
                            unCheckedChildren={<i className="mdi mdi-close" />}
                          />
                          <label className="formLabel">Stars</label>
                        </div>
                        <div className="input-group input-switch w-auto">
                          <Switch
                            size="small"
                            className="formControl bg-green"
                            checkedChildren={<i className="mdi mdi-check" />}
                            unCheckedChildren={<i className="mdi mdi-close" />}
                          />
                          <label className="formLabel">EZ Cap</label>
                        </div>
                        {/* <div className="input-group w-auto">
                    <Checkbox>House Charge</Checkbox>
                  </div> */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group input-md">
                        <MaskedInput className="formControl" name="ssn" placeholder="SSN" mask="111 11 1111" />
                        <label className="formLabel">SSN</label>
                      </div>
                      <div className="input-group input-md">
                        <Input className="formControl" placeholder="Medicare #" />
                        {/* <MaskedInput className="formControl" placeholder="Medicare #" mask="1111-111-1111" name="medicareNo" /> */}
                        <label className="formLabel">Medicare #</label>
                      </div>
                      <div className="input-group input-md">
                        <Input className="formControl" placeholder="Driving License #" />
                        <label className="formLabel">Driving License #</label>
                      </div>
                      <div className="input-group input-state input-xs">
                        <MaskedInput className="formControl" placeholder="St" mask="AA" name="state" />
                        <label className="formLabel">St.</label>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="col-12 my-3">
                  <div className="input-group input-lg">
                    <Select placeholder="Select" className="formControl" defaultValue="0-Not Specified">
                      <Option value="0">0-Not Specified</Option>
                      <Option value="1">1-Home</Option>
                      <Option value="2">2-Inter Care</Option>
                      <Option value="3">3-Nursing Home</Option>
                      <Option value="4">4-LTC/Extended care</Option>
                      <Option value="5">5-Rest Home</Option>
                      <Option value="6">6-Boarding Home</Option>
                      <Option value="7">7-Skilled Care Facility</Option>
                      <Option value="8">8-Sub-Acute Care Facility</Option>
                      <Option value="9">9-Acute Care Facility</Option>
                      <Option value="10">10-Hospice</Option>
                      <Option value="11">11-Homeless Selther</Option>
                    </Select>
                    <label className="formLabel">Patient Residence</label>
                  </div>
                  <div className="d-flex justify-content-end">
                    <div className="input-group input-md">
                      <Select placeholder="Select" className="formControl" defaultValue="0-Not Specified">
                        <Option value="0">0-Not Specified</Option>
                      </Select>
                      <label className="formLabel">Race</label>
                    </div>
                    <div className="input-group input-md">
                      <Select placeholder="Select" className="formControl" defaultValue="0-Not Specified">
                        <Option value="0">0-Not Specified</Option>
                      </Select>
                      <label className="formLabel">Ethnicity</label>
                    </div>
                    <div className="input-group input-md">
                      <Select placeholder="Select" className="formControl" defaultValue="0-Not Specified">
                        <Option value="0">0-Not Specified</Option>
                      </Select>
                      <label className="formLabel">Occupation</label>
                    </div>
                  </div>
                </div>
                <section className="contact-info outer-title pat-address border-bottom-0 border-top-0 mb-0">
                  <div className="section-title">
                    <span className="title-text">Home Address</span>
                  </div>
                  <ContactInfo state={state} type={"company"} handleChange={handleChange} />
                </section>
                <div className="col-12 pr-2">
                  <section>
                    <Button
                      tabindex="-1"
                      className="btn btn-family-member float-right ml-auto mt-3"
                      icon={<i className="mdi mdi-plus-circle" />}
                      onClick={() => setFamilyMember(true)}>
                      Family Member
                    </Button>
                    <FamilyMemberTable tabindex="-1" />
                  </section>
                </div>
              </div>
            </div>
            <div className="section-divider">
              <hr axis="y"></hr>
            </div>
            <div className="col-lg-5 px-0 d-flex flex-wrap align-items-center">
              <div className="col-12 mt-3 px-0">
                <section className="border-0 mt-0 pr-0  ">
                  <div className="input-group input-group-radio mb-3">
                    <Radio.Group list size="large" onChange={handleChange} defaultValue={0}>
                      <span className="radio-label mb-3 pl-0" style={{ fontSize: "1.25rem", color: "green" }}>
                        Will Call
                      </span>
                      <Radio value={0}>Pickup</Radio>
                      <Radio value={1}>Delivery</Radio>
                    </Radio.Group>
                  </div>
                  {state.willCall === 1 ? <DeliveryAddress /> : ""}
                </section>
              </div>
              <section className="pat-emergency-info outer-title mb-0 border-bottom-0 my-3">
                <div className="section-title">
                  <span className="title-text">Emergency Contact</span>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="Last Name" />
                      <label className="formLabel">Last Name</label>
                    </div>
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="First Name" />
                      <label className="formLabel">First Name</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group input-phone">
                      <MaskedInput
                        id="emergencyTel"
                        className="formControl"
                        placeholder="Cell"
                        mask="111 111 1111"
                        name="emergencyTel"
                        prefix={
                          <Tooltip title="Cell" position="top">
                            <i className="mdi mdi-cellphone-iphone"></i>
                          </Tooltip>
                        }
                      />
                    </div>
                    <div className="input-group input-phone">
                      <MaskedInput
                        id="emergencyTel"
                        className="formControl"
                        placeholder="Home Tel"
                        mask="111 111 1111"
                        name="emergencyTel"
                        prefix={
                          <Tooltip title="Home Tel" position="top">
                            <i className="mdi mdi-phone"></i>
                          </Tooltip>
                        }
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section className="outer-title pb-0 border-bottom-0 mb-3">
                <div className="section-title">
                  <span className="title-text">Caregiver Contact</span>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="Last Name" />
                      <label className="formLabel">Last Name</label>
                    </div>
                    <div className="input-group input-lg">
                      <Input className="formControl" placeholder="First Name" />
                      <label className="formLabel">First Name</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group input-phone">
                      <MaskedInput
                        id="emergencyTel"
                        className="formControl"
                        placeholder="Cell"
                        mask="111 111 1111"
                        name="emergencyTel"
                        prefix={
                          <Tooltip title="Cell" position="top">
                            <i className="mdi mdi-cellphone-iphone"></i>
                          </Tooltip>
                        }
                      />
                    </div>
                    <div className="input-group input-phone">
                      <MaskedInput
                        id="emergencyTel"
                        className="formControl"
                        placeholder="Home Tel"
                        mask="111 111 1111"
                        name="emergencyTel"
                        prefix={
                          <Tooltip title="Home Tel" position="top">
                            <i className="mdi mdi-phone"></i>
                          </Tooltip>
                        }
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section className="outer-title bg-secondary mb-0 pb-0 border-bottom-0 mt-4">
                <div className="section-title">
                  <span className="title-text">MedSYNC Info.</span>
                </div>
                <div className="input-group input-date">
                  <DatePicker className="formControl" placeholder="MM/DD/YYYY" format="MM/DD/YYYY" tabIndex="-1" />
                  <label className="formLabel">MedSYNC Date</label>
                </div>
                <div className="d-inline-block w-100">
                  <div className="input-group input-date with-addon">
                    <div className="input-group-prepend">
                      <Checkbox for="medSyncAttestation" tabIndex="-1"></Checkbox>
                    </div>
                    <DatePicker
                      className="formControl"
                      id="medSyncAttestation"
                      placeholder="MM/DD/YYYY"
                      format="MM/DD/YYYY"
                      style={{ width: "200px" }}
                      tabIndex="-1"
                    />
                    <label className="formLabel">MedSYNC Attest</label>
                  </div>
                  <div className="input-group input-md">
                    <Input className="formControl" placeholder="Attested By" style={{ width: "140px" }} disabled tabIndex="-1" />
                    <label className="formLabel">Attested By</label>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* <hr className="mb-0"></hr> */}
        </section>
      </form>
    </>
  );
}
