import React from "react";
// import { Modal, Button, InputNumber } from "antd";
import { Link } from "react-router-dom";
import { Modal, Button, Switch, Checkbox, InputNumber, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { printLabelState } from "../../../../utils/constant/notification/atom";
import { useRecoilState } from "recoil";
import { MaskedInput } from "antd-mask-input";

export default function PrintLabel() {
  const history = useHistory();
  const [isPrintLabel, setPrintLabel] = useRecoilState(printLabelState);

  const handleOk = async (e) => {
    setPrintLabel(false);
    history.push("processing-detail");
    // message.success("Electronic Refill Request sent successfully!");
  };

  const handleCancel = async (e) => {
    setPrintLabel(false);
  };

  return (
    <>
      <Modal
        width={450}
        className="modal-print-label"
        title="Print Label"
        visible={isPrintLabel}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <section className="bg-secondary m-0">
            <div className="detail-block mx-auto">
              <div className="input-group width-60">
                <MaskedInput
                  className="formControl"
                  placeholder="--- ------- --"
                  mask="111 1111111 11"
                  size="large"
                  autoFocus
                  prefix={
                    <>
                      <i className="mdi mdi-pound"></i>
                      <i className="mdi mdi-prescription"></i>
                    </>
                  }
                />
              </div>
              <div className="input-group width-40">
                <InputNumber className="formControl" placeholder="0" />
                <label className="formLabel">No. of Labels</label>
              </div>
              <Tooltip title="Print Label" placement="bottom">
                <Button className="btn btn-icon btn-print float-right m-0" onClick={handleOk}>
                  <span></span>
                </Button>
              </Tooltip>
            </div>
          </section>
          <section className="bg-primary border-0 mb-0">
            <div className="detail-block d-flex mt-3">
              <div className="detail-info w-100">
                <label className="label-info patient">Patient</label>
                <span style={{ fontSize: "1.15rem" }}>Patient, test</span>
              </div>
              <div className="detail-info">
                <label className="label-info">DOB</label>
                <span className="text-center" style={{ fontSize: "1.15rem" }}>
                  10-25-1974
                </span>
              </div>
            </div>
          </section>

          <section className="bg-secondary border-0 my-1">
            <div className="detail-block d-inline-block w-100 my-3">
              <div className="detail-info">
                <label className="label-info">NDC</label>
                <span style={{ fontSize: "1.15rem" }}>12345-6897-00</span>
              </div>
            </div>
            <div className="detail-block d-inline-block w-100 my-3">
              <div className="detail-info">
                <label className="label-info">Fill date</label>
                <span style={{ fontSize: "1.15rem" }}>12/15/2015</span>
              </div>
              <div className="detail-info float-right">
                <label className="label-info">Rx #</label>
                <span style={{ fontSize: "1.15rem" }}>123456897-00</span>
              </div>
            </div>
            <div className="detail-block d-inline-block w-100 my-1">
              <div className="detail-info w-100">
                <label className="label-info">Drug</label>
                <span>Amoxicillin</span>
              </div>
            </div>
            <div className="detail-block w-100 my-1">
              <div className="d-flex w-100">
                <div className="detail-info w-100">
                  <label className="formLabel">Stg.</label>
                  <span>875 M</span>
                </div>
                <div className="detail-info">
                  <label className="formLabel">Form</label>
                  <span>Tab</span>
                </div>
              </div>
            </div>
            <div className="detail-block d-inline-block w-100 my-1">
              <div className="detail-info w-100">
                <label className="formLabel">SIG</label>
                <span style={{ height: 50 }}>3 Times Daily</span>
              </div>
            </div>
            <div className="detail-block d-flex align-items-end justify-content-between w-100 my-1 text-center">
              <div className="d-flex align-items-end">
                <div className="detail-info">
                  <label style={{ fontSize: ".9rem", lineHeight: "1", top: "-.45rem" }} className="formLabel">
                    DQ
                  </label>
                  <span style={{ fontSize: "1.75rem", lineHeight: "1.4", background: "#a5c9e2" }}>20</span>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-primary border-0 my-0">
            <div className="detail-block d-flex">
              <div className="detail-info width-100 mt-3">
                <label className="label-info">Prescriber</label>
                <span style={{ fontSize: "1.15rem" }}>Prescriber, test</span>
              </div>
            </div>
          </section>
          <section className="mt-2" style={{ background: "#7ED6F2" }}>
            <div className="section-title d-none">
              <Checkbox className="title-text">Split Fill</Checkbox>
            </div>

            <div className="detail-block w-100">
              <div className="input-group input-ndc input-lg with-addon">
                <MaskedInput className="formControl" placeholder="NDC" size="large" mask="11111-1111-11" />
                <label className="formLabel">NDC</label>
              </div>
              <div className="input-group input-qty">
                <InputNumber className="formControl" placeholder="0" />
                <label className="formLabel">Qty</label>
              </div>
              <Tooltip title="Print Label" placement="bottom">
                <Button className="btn btn-icon btn-print float-right m-0">
                  <span></span>
                </Button>
              </Tooltip>
            </div>
            <div className="detail-block w-100">
              <div className="input-group input-ndc input-lg with-addon">
                <MaskedInput className="formControl" placeholder="NDC" size="large" mask="11111-1111-11" />
                <label className="formLabel">NDC</label>
              </div>
              <div className="input-group input-qty">
                <InputNumber className="formControl" placeholder="0" />
                <label className="formLabel">Qty</label>
              </div>
              <Tooltip title="Print Label" placement="bottom">
                <Button className="btn btn-icon btn-print float-right m-0">
                  <span></span>
                </Button>
              </Tooltip>
            </div>
          </section>
        </>
      </Modal>
    </>
  );
}
