/* eslint-disable no-unused-vars */
import React from "react";
import { Modal, Button, Switch, Tooltip, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useRecoilState } from "recoil";
import { statusState } from "../../../utils/constant/atom";

const SetStatus = ({ label, switchColor, customClasses, isActive, ...otherProps }) => {
  const [isStatusState, setStatusState] = useRecoilState(statusState);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState("Content of the modal");

  const setStatusModal = () => {
    setStatusState(true);
  };

  const handleOk = () => {
    if (otherProps.state.inActiveMessage === "") {
      return message.error("Message Required");
    }
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);

    setStatusState(true);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    otherProps.setState({
      ...otherProps.state,
      isActive: false,
    });
    setStatusState(false);
  };

  // const [switchColors, setSwitchColor] = React.useState(switchColors); ${[...switchColors]}`}

  return (
    // USAGE
    // <SetStatus label="Label Text" visible={isStatusState} statusState={setStatusState} />
    <>
      <Switch
        checked
        size="small"
        className={`formControl ${customClasses}`}
        checkedChildren={<i className="mdi mdi-check" />}
        unCheckedChildren={<i className="mdi mdi-close" />}
        {...otherProps}
        checked={isActive}
        onChange={(e) =>
          otherProps.setState({
            ...otherProps.state,
            isActive: e,
          })
        }
      />
      <label className="formLabel">{label}</label>
      <Modal
        className="setStatus-modal"
        title="InActive Reason"
        closable={false}
        maskClosable={false}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        visible={isStatusState}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <div className="row align-items-center">
          <div className="col-12">
            {/* value={otherProps.state.inActiveMessage}  */}
            <TextArea
              className="input-notes"
              onChange={(e) =>
                otherProps.setState({
                  ...otherProps.state,
                  inActiveMessage: e.target.value,
                })
              }
              placeholder="Add Reason"
              rows={4}
              autoFocus
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SetStatus;
