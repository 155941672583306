import React, { Suspense, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Popconfirm, message, Radio, Input, Tooltip, Select, DatePicker, Table, Switch, Space } from "antd";
import SectionLoader from "Components/common/loader/section";
import SetStatus from "../../../components/common/setStatus";
import { MaskedInput } from "antd-mask-input";
import AddNotes from "Components/common/notes/addNotes";
import { vendorDetailState } from "../../../utils/constant/vendor/atom";
import { useRecoilState } from "recoil";
import Address from "./components/address";

function VendorDetail() {
  const [isVendorDetail, setVendorDetail] = useRecoilState(vendorDetailState);
  const vendorDetailModal = () => {
    setVendorDetail(true);
  };
  const handleOk = async (e) => {
    setVendorDetail(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setVendorDetail(false);
    message.error("Data not saved.");
  };

  function cancel(e) {
    message.error("Click on No");
  }
  function confirm(e) {
    message.success("Click on Yes");
  }

  const columnsContactInfo = [
    {
      title: `Name`,
      dataIndex: "name",
      key: "name",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (chName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={chName}>
          {chName}
        </Tooltip>
      ),
    },
    {
      title: "Tel",
      dataIndex: "tel",
      key: "tel",
      className: "col-tcf",
      width: 100,
    },
    {
      title: "Cell",
      dataIndex: "cell",
      key: "cell",
      className: "col-tcf",
      width: 100,
    },
    {
      title: "Fax",
      dataIndex: "fax",
      key: "fax",
      className: "col-tcf",
      width: 100,
    },
    {
      title: `email`,
      dataIndex: "email",
      key: "email",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={email}>
          {email}
        </Tooltip>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      width: 120,
      render: () => (
        <Space size="middle" className="actions">
          <Popconfirm title="Are you sure delete this task?" onConfirm={confirm} onCancel={cancel} okText="Yes" cancelText="No">
            <Tooltip title="Delete" mouseLeaveDelay={0} placement="bottom">
              <Link className="action-delete"></Link>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const addContactInfo = [];

  return (
    <>
      <Button className="btn float-right" icon={<i className="mdi mdi-plus-circle" />} onClick={() => vendorDetailModal(true)}>
        Vendor
      </Button>
      <Modal
        style={{ top: 20 }}
        width={800}
        title="Add Vendor"
        visible={isVendorDetail}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <section className="bg-primary">
          <div className="vendor-detail">
            <div className="row">
              <div className="col-12">
                <Suspense
                  fallback={
                    <div className="loader">
                      <SectionLoader />
                    </div>
                  }>
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-sm">
                        <Input className="formControl" disabled />
                        <label className="formLabel">Vendor Code</label>
                      </div>
                      <div className="input-group input-xxl required">
                        <Input className="formControl" placeholder="Vendor Name" autoFocus />
                        <label className="formLabel">Vendor Name</label>
                      </div>
                      <Suspense>
                        <div className="input-group input-switch color-green w-auto float-right">
                          <SetStatus label="Status" />
                        </div>
                      </Suspense>
                    </div>
                    <section className="contact-info border-0 px-3">
                      <Address />
                      <div className="input-group input-phone">
                        <MaskedInput
                          className="formControl"
                          placeholder="Phone"
                          mask="111 111 1111"
                          name="cellPhone"
                          prefix={
                            <Tooltip title="Cell" position="top">
                              <i className="mdi mdi-phone"></i>
                            </Tooltip>
                          }
                        />
                      </div>
                      <div className="input-group input-phone">
                        <MaskedInput
                          className="formControl"
                          placeholder="Fax"
                          mask="111 111 1111"
                          name="cellPhone"
                          prefix={
                            <Tooltip title="Fax" position="top">
                              <i className="mdi mdi-fax"></i>
                            </Tooltip>
                          }
                        />
                      </div>
                      <div className="input-group input-email">
                        <Input
                          className="formControl"
                          placeholder="eMail"
                          name="email"
                          prefix={
                            <Tooltip title="Email" position="top">
                              <i className="mdi mdi-email"></i>
                            </Tooltip>
                          }
                        />
                      </div>
                    </section>
                  </div>
                </Suspense>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-primary">
          <div className="section-title">
            <span className="title-text">Contact Info.</span>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-10">
              <div className="row">
                <div className="col-12">
                  <div className="input-group input-lg required">
                    <Input className="formControl" placeholder="Last Name" />
                    <label className="formLabel">Last Name</label>
                  </div>
                  <div className="input-group input-lg required">
                    <Input className="formControl" placeholder="First Name" />
                    <label className="formLabel">First Name</label>
                  </div>
                  <div className="input-group input-md">
                    <Input className="formControl" placeholder="MI" />
                    <label className="formLabel">MI</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="input-group input-phone">
                    <MaskedInput
                      className="formControl"
                      placeholder="Tel"
                      mask="111 111 1111"
                      name="cellPhone"
                      prefix={
                        <Tooltip title="Tel" position="top">
                          <i className="mdi mdi-phone"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="input-group input-phone">
                    <MaskedInput
                      className="formControl"
                      placeholder="Cell"
                      mask="111 111 1111"
                      name="cellPhone"
                      prefix={
                        <Tooltip title="Cell" position="top">
                          <i className="mdi mdi-cellphone-iphone"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="input-group input-phone">
                    <MaskedInput
                      className="formControl"
                      placeholder="Fax"
                      mask="111 111 1111"
                      name="cellPhone"
                      prefix={
                        <Tooltip title="Fax" position="top">
                          <i className="mdi mdi-fax"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                  <div className="input-group input-email">
                    <Input
                      className="formControl"
                      placeholder="eMail"
                      name="email"
                      prefix={
                        <Tooltip title="Email" position="top">
                          <i className="mdi mdi-email"></i>
                        </Tooltip>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2">
              <Button className="btn btn-icon btn-lg btn-add">
                <span></span>
              </Button>
            </div>
          </div>
          <Table
            className="table-length length-five"
            columns={columnsContactInfo}
            dataSource={addContactInfo}
            pagination={false}
            scroll={{ y: 150 }}
            rowKey={(record) => record.uid}
          />
        </section>
      </Modal>
    </>
  );
}

export default VendorDetail;
