/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import LoginPage from "./pages/Auth/Login";
import AppLayout from "./container/AppLayout";
import { useRecoilState } from "recoil";
import { isLogedIn } from "./utils/constant/atom";
import { stateArray } from "./utils/constant/company/atom";
import { fetchStates } from "./utils/methods/apiMethods";

function PharmacyApp() {
  const [isLogIn, setisLogIn] = useRecoilState(isLogedIn);
  const [state, setState] = useRecoilState(stateArray);

  useEffect(() => {
    async function fetchloginState() {
      await fetchState();

      let isLogin = localStorage.getItem("isLogIn");
      if (isLogin !== null && isLogin !== "false") {
        setisLogIn(isLogin);
      } else {
        setisLogIn(false);
      }
    }

    fetchloginState();
  }, [setisLogIn]);

  const fetchState = async () => {
    let response = await fetchStates();

    setState(response.data.object);
  };

  return <>{isLogIn ? <AppLayout /> : <LoginPage />}</>;
}

export default PharmacyApp;
