import React, { Suspense, useState } from "react";
import { Modal, Button, Popconfirm, message, Radio, Input, Tooltip, Select, DatePicker, InputNumber, Space, Table, Divider } from "antd";
import { useRecoilState } from "recoil";
import SectionLoader from "../../loader/section";
import { refillRxState } from "../../../../utils/constant/notification/atom";
import NoRefill from "./components/no-refills";
import ReadyToFill from "./components/ready-to-fill";
import { sendRreState } from "../../../../utils/constant/processing/atom";
import SendRre from "../../../../pages/Private/Processing/components/refills";
import { SearchOutlined } from "@ant-design/icons";
import InputMask from "antd-mask-input/build/main/lib/inputmask-core";
import { MaskedInput } from "antd-mask-input";
import SearchPatient from "../../searchPatient";

export default function RefillRx() {
  const [isRefillRx, setRefillRx] = useRecoilState(refillRxState);

  const [isSendRre, setSendRre] = useRecoilState(sendRreState);

  const sendRreModal = () => {
    setSendRre(true);
  };

  const handleOk = async (e) => {
    setRefillRx(false);
  };

  const handleCancel = async (e) => {
    setRefillRx(false);
  };

  const onChange = async (current) => {
    console.log("onChange:", current);
    setRefillRx(current);
  };

  return (
    <>
      <Suspense fallback={<SectionLoader />}>
        <div className="d-none">
          <SendRre />
        </div>
      </Suspense>
      <Modal
        width={1800}
        style={{ top: 25 }}
        className="modal-erx-wizard"
        title={
          <>
            <span>Refill</span>
            <i className="mdi mdi-prescription"></i>
          </>
        }
        visible={isRefillRx}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <>
          <div className="d-none">
            <Suspense fallback={<SectionLoader />}></Suspense>
          </div>
          <section className="filter-bar mb-2">
            <form className="d-flex align-items-center justify-content-start">
              <div className="d-flex">
                <div className="input-group input-xl">
                  <MaskedInput
                    mask="111 1111111 11"
                    className="formControl"
                    placeholder="--- ------- --"
                    prefix={<i className="mdi mdi-prescription"></i>}
                  />
                </div>
                <div className="input-group input-xxl">
                  <SearchPatient />
                </div>
              </div>
              <Tooltip title="Search Patient History" mouseLeaveDelay={0}>
                <Button className="btn btn-sm btn-search" icon={<SearchOutlined />} />
              </Tooltip>
              <Button className="btn btn-clear-all">Clear All</Button>
            </form>
          </section>
          <NoRefill />
          <ReadyToFill />
          <div className="btn-bar action-btns">
            <div className="float-right">
              <Button className="btn btn-e-refill" onClick={() => sendRreModal(true)}>
                E-Refill
              </Button>
              <hr axis="y" className="button-divider"></hr>
              <Button className="btn btn-delete">Delete</Button>
              <hr axis="y" className="button-divider"></hr>
              <Button className="btn btn-fax btn-refill">Fax Refill</Button>
              <Button className="btn btn-process">Process</Button>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}
