import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderNotification from "./headerNotification";
import UserControlles from "./headerUserControlles";
import { useLocation } from "react-router-dom";
import { Badge, Dropdown, Menu, Tooltip } from "antd";
import { useRecoilState } from "recoil";
import ProductOtcDetail from "../../../pages/Private/Product/component/proOtcDetail";
import { find } from "lodash";
import { adminMenus, privateMenus } from "../../../utils/menu/route";
import { siteTitle } from "../../../utils/constant/atom";
import RejectedClaim from "../../common/notification/rejectedClaim";
import { rejectedClaimState } from "../../../utils/constant/notification/atom";
import rejectedClaims from "../../../assets/img/notification/Rejected-Claim-Default.png";
const type = localStorage.getItem("type");
let menuRoute = [];
let menu = [];

if (type === "ADMIN") {
  menu = adminMenus;
} else if (type === "PRIVATE") {
  menu = privateMenus;
}
menu.forEach((item) => {
  if (item.child.length === 0) {
    menuRoute.push(item);
  } else {
    item.child.forEach((record) => {
      menuRoute.push(record);
    });
  }
});

const HeaderNav = () => {
  const location = useLocation();
  const [title, setTitle] = useRecoilState(siteTitle);
  const [isRejectedClaim, setRejectedClaim] = useRecoilState(rejectedClaimState);

  useEffect(() => {
    let currentRoute = find(menuRoute, ["path", location.pathname]);
    setTitle(currentRoute !== undefined ? currentRoute.title : "");
  }, [location, setTitle]);

  console.log(location.pathname);
  const menuProcessing = (
    <Menu key="menuMain" className="presc-menu">
      {/* <ProcessingCount /> */}
      <li className="active">
        <Link to="/private/processing" key="1">
          <i className="mdi mdi-numeric-1-box"></i> <span>Doe, John</span>, 02-17-1978
        </Link>
      </li>
      <li>
        <Link to="/private/processing" key="2">
          <i className="mdi mdi-numeric-2-box"></i> <span>Doe, Jane</span>, 02-17-1978
        </Link>
      </li>
      <li>
        <Link to="/private/processing" key="3">
          <i className="mdi mdi-numeric-3-box"></i> <span>FirstName, LastName</span>, DOB
        </Link>
      </li>
    </Menu>
  );

  return (
    <>
      <div className="d-none">
        <ProductOtcDetail />
        <RejectedClaim />
      </div>
      <nav className="pl-4">
        {localStorage.getItem("type") === "PRIVATE" && location.pathname !== "/404" ? (
          <ul className="float-left">
            <li className={window.location.href.indexOf("/processing") > -1 ? "active" : ""}>
              <Dropdown overlay={menuProcessing} placement="topLeft" arrow>
                <span className="ant-dropdown-link">
                  <Link to="/private/processing">
                    <i className="mdi mdi-prescription"></i>
                  </Link>
                </span>
              </Dropdown>
              {/* <span className="patient-info">John, Doe</span> */}
            </li>
            <li className={window.location.href.indexOf("/processing-detail") > -1 ? "active" : ""}>
              <Link to="/private/processing-detail">Processing</Link>
            </li>
            <li className={window.location.href.indexOf("/order") > -1 ? "active" : ""}>
              <Tooltip title="Order" placement="bottom">
                <Link to="/private/order">
                  <i className="mdi mdi-progress-clock"></i>
                </Link>
              </Tooltip>
            </li>
            <hr axis="y" />
            <li className={window.location.href.indexOf("/patient") > -1 ? "active" : ""}>
              <Tooltip title="Patient" placement="bottom">
                <Link to="/private/patient">
                  <i className="mdi mdi-account-search"></i>
                </Link>
              </Tooltip>
            </li>
            <li className={window.location.href.indexOf("/prescriber") > -1 ? "active" : ""}>
              <Tooltip title="Prescriber" placement="bottom">
                <Link to="/private/prescriber">
                  <i className="mdi mdi-stethoscope"></i>
                </Link>
              </Tooltip>
            </li>
            <li className={window.location.href.indexOf("/product/drugSearch") > -1 ? "active" : ""}>
              <Tooltip title="Drugs" placement="bottom">
                <Link to="/private/product/drugSearch">
                  <i className="mdi mdi-pill"></i>
                </Link>
              </Tooltip>
            </li>
            <hr axis="y" />
            <li className={window.location.href.indexOf("/binman") > -1 ? "active" : ""}>
              <Tooltip title="BinMan" placement="bottom">
                <Link to="/private/binman">
                  <i className="mdi mdi-nutrition"></i>
                </Link>
              </Tooltip>
            </li>
            <li className={window.location.href.indexOf("/wimo") > -1 ? "active" : ""}>
              <Tooltip title="WIMO (Where is my order)" placement="bottom">
                <Link to="/private/wimo">
                  <i className="mdi mdi-timetable"></i>
                </Link>
              </Tooltip>
            </li>
            <li className={window.location.href.indexOf("/product/otcSearch") > -1 ? "active" : ""}>
              <Tooltip title="Retail Products" placement="bottom">
                <Link to="/private/product/octSearch">
                  <i className="mdi mdi-cart"></i> {/*mdi-package-variant*/}
                </Link>
              </Tooltip>
            </li>
          </ul>
        ) : (
          ""
        )}
        <ul className="ml-auto float-right">
          {localStorage.getItem("type") === "PRIVATE" && location.pathname !== "/404" ? <HeaderNotification /> : ""}
          {location.pathname !== "/404" ? <UserControlles /> : ""}
        </ul>
      </nav>
    </>
  );
};

export default HeaderNav;
