const { atom } = require("recoil");

export const isLogedIn = atom({
  key: "isLogedIn",
  default: false,
});
export const isLogedType = atom({
  key: "isLogedType",
  default: "",
});
export const accessTokenState = atom({
  key: "accessTokenState",
  default: "",
});
export const refreshTokenState = atom({
  key: "refreshTokenState",
  default: "",
});
export const isUrl = atom({
  key: "isUrl",
  default: "",
});

export const siteTitle = atom({
  key: "siteTitle",
  default: "DashBoard",
});

export const statusState = atom({
  key: "statusState",
  default: false,
});

// NOTES
export const noteTitleState = atom({
  key: "noteTitleState",
  default: "",
});
export const notesDrawerState = atom({
  key: "notesDrawerState",
  default: false,
});

export const quickNoteState = atom({
  key: "quickNoteState",
  default: false,
});

export const partialFillState = atom({
  key: "partialFillState",
  default: false,
});
