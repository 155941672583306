import React, { Suspense, useState } from "react";
import { Modal, Button, Popconfirm, message, Radio, Input, Tooltip, Select, DatePicker, Checkbox, Switch } from "antd";
import { MaskedInput } from "antd-mask-input";
import SectionLoader from "Components/common/loader/section";
import LinkClinic from "./components/linkClinic";
import { useRecoilState } from "recoil";
import { prescriberDetailState } from "../../../utils/constant/prescriber/atom";
import { notesDrawerState, noteTitle, statusState } from "../../../utils/constant/atom";
import NotePreview from "../../../components/common/notes/previewNote";
import SetStatus from "Components/common/setStatus";
import PrescriberClinicSearch from "./components/prescriberClinicSearchModal";
import TextArea from "antd/lib/input/TextArea";

function PrescriberDetail() {
  const [isPrescriberDetail, setPrescriberDetail] = useRecoilState(prescriberDetailState);
  const [isStatusState, setStatusState] = useRecoilState(statusState);
  const [state, setState] = useState({
    deseasedDateDis: false,
  });

  const handleOk = async (e) => {
    setPrescriberDetail(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setPrescriberDetail(false);
    message.error("Data not saved.");
  };

  const onChange = async (current) => {
    console.log("onChange:", current);
    // setCurrent(current);
  };

  const Option = { Select };

  return (
    <>
      <div className="d-none">
        <PrescriberClinicSearch />
      </div>
      <Button className="btn float-right" icon={<i className="mdi mdi-plus-circle" />} onClick={() => setPrescriberDetail(true)}>
        Prescriber
      </Button>
      <Modal
        width={1400}
        title="Add Prescriber"
        visible={isPrescriberDetail}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <div className="prescriber-detail">
          <div className="row">
            <div className="col-12">
              <Suspense
                fallback={
                  <div className="loader">
                    <SectionLoader />
                  </div>
                }>
                <div className="row flex-nowrap w-100 pr-4 position-relative">
                  <div className="col-lg-7 pr-0">
                    <section className="bg-primary">
                      <div className="row">
                        <div className="col-12">
                          <div className="input-group input-lg">
                            <Input className="formControl" placeholder="Last Name" autoFocus />
                            <label className="formLabel">Last Name</label>
                          </div>
                          <div className="input-group input-lg required">
                            <Input className="formControl" placeholder="First Name" />
                            <label className="formLabel">First Name</label>
                          </div>
                          <div className="input-group input-lg">
                            <Input className="formControl" placeholder="MI" />
                            <label className="formLabel">MI</label>
                          </div>
                          <div className="input-group input-switch color-green w-auto float-right">
                            <SetStatus
                              defaultChecked
                              label="Status"
                              state={state}
                              setState={setState}
                              onClick={() => setStatusState(true)}
                              tabindex="-1"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-group input-group-radio w-auto">
                            <Radio.Group defaultValue={1}>
                              <span className="radio-label">Gender</span>
                              <Radio value={1}>M</Radio>
                              <Radio value={2}>F</Radio>
                              <Radio value={3}>U</Radio>
                            </Radio.Group>
                          </div>
                          <div className="d-flex justify-content-end">
                            <div className="input-group input-md">
                              <Select className="formControl" defaultValue="0">
                                <Option value="0">Not Specified</Option>
                                <Option value="1">MD</Option>
                                <Option value="2">DO</Option>
                                <Option value="3">NP</Option>
                                <Option value="4">PA</Option>
                                <Option value="5">OD</Option>
                              </Select>
                              <label className="formLabel">Degree</label>
                            </div>
                            <div className="input-group input-lg">
                              <Select className="formControl" defaultValue="0">
                                <Option value="0">Not Specified</Option>
                                <Option value="1">Cardiology</Option>
                                <Option value="2">Dermatology</Option>
                                <Option value="3">Gynecology</Option>
                                <Option value="4">Abdominanl Surgey</Option>
                                <Option value="5">Hemaeology</Option>
                              </Select>
                              <label className="formLabel">Speciality</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-group input-md">
                            <MaskedInput className="formControl" placeholder="NPI" mask="1111111111" name="npi" />
                            <Tooltip title="National Provider Identifier">
                              <label className="formLabel">NPI</label>
                            </Tooltip>
                          </div>
                          <div className="input-group input-md">
                            <Input className="formControl input-dea" placeholder="DEA" />
                            <Tooltip title="Drug Enforcement Administration">
                              <label className="formLabel">DEA</label>
                            </Tooltip>
                          </div>
                          <div className="input-group input-md">
                            <Input className="formControl input-lic" placeholder="LIC" />
                            <Tooltip title="License #">
                              <label className="formLabel">LIC</label>
                            </Tooltip>
                          </div>
                          <div className="input-group input-state input-xs">
                            <MaskedInput className="formControl" placeholder="St" mask="AA" name="state" />
                            <label className="formLabel">St.</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex">
                            <div className="input-group input-md">
                              {/* <MaskedInput id="ssId" className="formControl" placeholder="Surescripts ID" mask="1111 111 11" name="ssId" /> */}
                              <Input className="formControl" placeholder="Surescripts ID" max="20" />
                              <Tooltip title="Surescript ID">
                                <label className="formLabel">SS ID</label>
                              </Tooltip>
                            </div>
                            <div className="input-group width-55">
                              <Input id="ssServiceLevel" name="ssServiceLevel" className="formControl" placeholder="SS Service Level" />
                              <Tooltip title="Surescript Service Level">
                                <label className="formLabel">SS Service Level</label>
                              </Tooltip>
                            </div>
                            {/* <div className="input-group input-switch input-md">
                              <Switch
                                size="small"
                                className="formControl"
                                checkedChildren={<i className="mdi mdi-check" />}
                                unCheckedChildren={<i className="mdi mdi-close" />}
                              />
                              <label className="formLabel">
                                Accepts <span className="tt-none">Rx</span> Change
                              </label>
                            </div>
                            <div className="input-group input-switch input-md">
                              <Switch
                                size="small"
                                className="formControl"
                                checkedChildren={<i className="mdi mdi-check" />}
                                unCheckedChildren={<i className="mdi mdi-close" />}
                              />
                              <label className="formLabel">Accepts e-Refill</label>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-12">
                          <section className="contact-info border-0 p-0 m-0 mt-3">
                            <div className="input-group input-phone">
                              <MaskedInput
                                className="formControl"
                                placeholder="Cell"
                                mask="111 111 1111"
                                name="cellPhone"
                                prefix={
                                  <Tooltip title="Cell" position="top">
                                    <i className="mdi mdi-cellphone-iphone"></i>
                                  </Tooltip>
                                }
                              />
                            </div>
                            <div className="input-group input-email">
                              <Input
                                className="formControl"
                                placeholder="eMail"
                                name="email"
                                prefix={
                                  <Tooltip title="Email" position="top">
                                    <i className="mdi mdi-email"></i>
                                  </Tooltip>
                                }
                              />
                            </div>
                          </section>
                        </div>
                      </div>
                    </section>
                    <div className="row my-3">
                      <div className="col-12">
                        <div className="d-flex">
                          <div className="input-group input-date with-addon">
                            <div className="input-group-prepend">
                              <Checkbox
                                for="deceasedDate"
                                checked={state.deseasedDateDis}
                                onChange={(e) => setState({ ...state, deseasedDateDis: e.target.checked })}></Checkbox>
                            </div>
                            <DatePicker
                              className="formControl"
                              id="deceasedDate"
                              placeholder="MM/DD/YYYY"
                              format="MM/DD/YYYY"
                              disabled={state.deseasedDateDis === true ? false : true}
                            />
                            <label className="formLabel">Deceased Date</label>
                          </div>
                          <div className="input-group">
                            <Input className="formControl" placeholder="Deceased Note" disabled={state.deseasedDateDis === true ? false : true} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section-divider">
                    <hr axis="y"></hr>
                  </div>
                  <div className="col-lg-5 pl-0">
                    <section className="border-0">
                      <div className="section-title">
                        <span className="title-text">Other Licenses</span>
                      </div>
                      <div className="row">
                        <div className="col-12 no-gutters">
                          <div className="input-group input-lg">
                            <div className="input-group-prepend">
                              <i className="mdi mdi-numeric-1-box"></i>
                            </div>
                            <Input className="formControl" name="medicare" placeholder="License Type" />
                            <label className="formLabel">License Type</label>
                          </div>
                          <div className="input-group input-md">
                            <Input className="formControl" placeholder="License #" mask="AA" name="licNo" />
                            <label className="formLabel">License #</label>
                          </div>
                          <div className="input-group input-state">
                            <MaskedInput className="formControl" placeholder="St" mask="AA" name="state" />
                            <label className="formLabel">St.</label>
                          </div>
                        </div>
                        <div className="col-12 no-gutters">
                          <div className="input-group input-lg">
                            <div className="input-group-prepend">
                              <i className="mdi mdi-numeric-2-box"></i>
                            </div>
                            <Input className="formControl" name="medicare" placeholder="License Type" />
                            <label className="formLabel">License Type</label>
                          </div>
                          <div className="input-group input-md">
                            <Input className="formControl" placeholder="License #" mask="AA" name="licNo" />
                            <label className="formLabel">License #</label>
                          </div>
                          <div className="input-group input-state">
                            <MaskedInput className="formControl" placeholder="St" mask="AA" name="state" />
                            <label className="formLabel">St.</label>
                          </div>
                        </div>
                        <div className="col-12 no-gutters">
                          <div className="input-group input-lg">
                            <div className="input-group-prepend">
                              <i className="mdi mdi-numeric-3-box"></i>
                            </div>
                            <Input className="formControl" name="medicare" placeholder="License Type" />
                            <label className="formLabel">License Type</label>
                          </div>
                          <div className="input-group input-md">
                            <Input className="formControl" placeholder="License #" mask="AA" name="licNo" />
                            <label className="formLabel">License #</label>
                          </div>
                          <div className="input-group input-state">
                            <MaskedInput className="formControl" placeholder="St" mask="AA" name="state" />
                            <label className="formLabel">St.</label>
                          </div>
                        </div>
                        <div className="col-12 no-gutters">
                          <div className="input-group input-lg">
                            <div className="input-group-prepend">
                              <i className="mdi mdi-numeric-4-box"></i>
                            </div>
                            <Input className="formControl" name="medicare" placeholder="License Type" />
                            <label className="formLabel">License Type</label>
                          </div>
                          <div className="input-group input-md">
                            <Input className="formControl" placeholder="License #" mask="AA" name="licNo" />
                            <label className="formLabel">License #</label>
                          </div>
                          <div className="input-group input-state">
                            <MaskedInput className="formControl" placeholder="St" mask="AA" name="state" />
                            <label className="formLabel">St.</label>
                          </div>
                        </div>
                      </div>
                    </section>
                    <div className="input-group input-note">
                      <TextArea className="formControl" placeholder="Notes" rows="3" />
                    </div>
                  </div>
                </div>
                <section className="bg-primary mt-3">
                  <div className="section-title">
                    <span className="title-text">Clinics</span>
                  </div>
                  <LinkClinic />
                </section>
              </Suspense>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PrescriberDetail;
