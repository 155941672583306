import React, { useState } from "react";
import { Tooltip, Typography } from "antd";

const NotePreview = ({ noteLabel, noteAction, children, ellipsis, ...props }) => {
  const { Paragraph } = Typography;
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip title={truncated ? children : undefined} getPopupContainer={(trigger) => trigger.parentElement}>
      <Paragraph {...props} ellipsis={{ suffix: <span className="username">--username</span>, ...ellipsis, onEllipsis: setTruncated }}>
        <>
          {noteAction && (
            <button className={`btn btn-icon`}>
              <span></span>
            </button>
          )}
          {noteLabel && <label className="noteLabel">{noteLabel}</label>}
          {children}
        </>
      </Paragraph>
    </Tooltip>
  );
};
export default NotePreview;
