import React from "react";
import { useLocation } from "react-router-dom";
import { Image, Carousel, Button, Tooltip } from "antd";
import PatientDummyDoc from "Assets/img/docs/patient-medical-record.png";
import FallBackImg from "Assets/img/product-thumbnail-images.jpg";
import { useRecoilState } from "recoil";
import { noteTitleState, quickNoteState } from "../../../../utils/constant/atom";
import NotePreview from "../../notes/previewNote";

export default function DocumentPreview() {
  const [title, setNoteTitle] = useRecoilState(noteTitleState);
  const [isQuickNote, setQuickNote] = useRecoilState(quickNoteState);
  const location = useLocation();

  const addRxAnnotation = (title) => {
    setQuickNote(true);
    setNoteTitle(title);
  };
  return (
    <>
      {location.pathname == "/private/processing-detail" ? (
        <>
          <div className="d-flex align-items-center bg-pri mb-0 p-1">
            <h3 className="rx-edit-text m-0">
              {/* <strong>Edit</strong> */}
              <span className="icon-rxNumber">
                <i className="mdi mdi-prescription"></i>
                <i className="mdi mdi-pound"></i>
              </span>
              00102020
            </h3>
            <div className="note-preview d-flex">
              <Tooltip title="Rx Annotation" placement="bottom">
                <Button size="small" className="btn btn-icon btn-rx-annotation m-0 px-1 py-0" onClick={() => addRxAnnotation("Rx Annotation")}>
                  <span></span>
                </Button>
              </Tooltip>
              <NotePreview onClick={() => addRxAnnotation("Rx Annotation")} placement="bottom">
                This is testing note for preview This is testing note for preview This is testing note for preview This is testing note for preview
                This is testing note for preview This is testing note for preview
              </NotePreview>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div className="preview-doc-wrapper">
        <Carousel dotPosition="bottom">
          <div className="preview-doc">
            <div className="preview-action">
              <Tooltip title="Default" placement="bottomLeft">
                <Button className="btn btn-icon btn-set-default">
                  <span></span>
                </Button>
              </Tooltip>
              <Tooltip title="Print Label" placement="bottom">
                <Button className="btn btn-icon btn-print">
                  <span></span>
                </Button>
              </Tooltip>
              <Tooltip title="Delete" placement="right">
                <Button className="btn btn-icon btn-delete">
                  <span></span>
                </Button>
              </Tooltip>
            </div>
            <Image src={PatientDummyDoc} fallback={FallBackImg}></Image>
          </div>
          <div className="preview-doc">
            <Image src={PatientDummyDoc} fallback={FallBackImg}></Image>
          </div>
          <div className="preview-doc">
            <Image src={PatientDummyDoc} fallback={FallBackImg}></Image>
          </div>
          {/* <GlassMagnifier imageSrc={docImg} largeImageSrc={docImg} imageAlt="Document Preview" magnifierSize="30%" square /> */}
        </Carousel>
      </div>
    </>
  );
}
