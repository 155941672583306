import { Button, Input, message, Popconfirm, Radio, Select, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function IcdCodes() {
  const familyMemberData = [];

  function cancel(e) {
    message.error("Click on No");
  }
  function confirm(e) {
    message.success("Click on Yes");
  }

  const columnsIcdCodes = [
    {
      title: `ICD Code`,
      dataIndex: "icdCode",
      key: "icdCode",
      width: 150,
    },
    {
      title: `Description`,
      dataIndex: "desc",
      key: "desc",

      ellipsis: {
        showTitle: false,
      },
      render: (desc) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={desc}>
          {desc}
        </Tooltip>
      ),
    },
    {
      title: `Drug Note`,
      dataIndex: "drugNote",
      key: "drugNote",
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (drugNote) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={drugNote}>
          {drugNote}
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      width: 70,
      render: () => (
        <Space size="middle" className="actions">
          <Popconfirm title="Are you sure delete this task?" onConfirm={confirm} onCancel={cancel} okText="Yes" cancelText="No">
            <Tooltip title="Delete" mouseLeaveDelay={0} placement="bottom">
              <Link className="action-delete"></Link>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // const { loading, selectedRowKeys } = this.state;
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: this.onSelectChange,
  // };
  // const hasSelected = selectedRowKeys.length > 0;
  const { Option } = Select;
  return (
    <section className="bg-primary">
      <div className="section-title">
        <span className="title-text">ICD(s)</span>
        <div className="ml-3 mt-2">
          <Radio.Group className="ml-3" defaultValue={1}>
            <Radio value={1}>Starts With</Radio>
            <Radio value={2}>Contains</Radio>
          </Radio.Group>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-12">
          <div className="input-group input-md">
            <Select showSearch placeholder="ICD Code">
              <Option value="pharmacist">A001</Option>
              <Option value="mother">A002</Option>
            </Select>
            <label className="formLabel">ICD Code</label>
          </div>
          <div className="input-group" style={{ width: "600px" }}>
            <Select showSearch placeholder="Description">
              <Option value="pharmacist">A001</Option>
              <Option value="mother">A002</Option>
            </Select>
            <label className="formLabel">Description</label>
          </div>
          <div className="input-group input-xxl">
            <Input className="formControl" placeholder="Drug Note" />
            <label className="formLabel">Drug Note</label>
          </div>

          <Button className="btn btn-icon btn-sm btn-add float-right">
            <span></span>
          </Button>
        </div>
      </div>
      <Table
        className="table-length length-ten"
        size="small"
        columns={columnsIcdCodes}
        dataSource={familyMemberData}
        pagination={false}
        scroll={{ y: 300 }}
        rowKey={(record) => record.uid}
      />
    </section>
  );
}
