import React, { useState } from "react";
import { Modal, Button, Popconfirm, message, Input, Select, Checkbox, Tooltip, InputNumber, Switch } from "antd";
import { MaskedInput } from "antd-mask-input";
import SetStatus from "Components/common/setStatus";
import TextArea from "antd/lib/input/TextArea";
import { groupState } from "../../../../utils/constant/patient/atom";
import { useRecoilState } from "recoil";
import { daysFormatter, daysParser } from "../../../../utils/methods/validation";

const { Option } = Select;

function InsGroupDetail() {
  const [checked, setChecked] = useState(false);
  const [isGroup, setGroup] = useRecoilState(groupState);

  const insGroupDetailModal = () => {
    setGroup(true);
  };

  const handleOk = async (e) => {
    setGroup(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setGroup(false);
    message.error("Data not saved!");
  };

  const handleChange = async (value) => {
    console.log(`selected ${value}`);
  };

  function handleShowMsg(e) {
    if (e.target.checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }

  return (
    <>
      <Button className="btn float-right" icon={<i className="mdi mdi-plus-circle" />} onClick={() => insGroupDetailModal(true)}>
        Insurance Group
      </Button>
      <Modal
        width={950}
        title="Add Insurance Group"
        visible={isGroup}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <section className="m-0 p-0">
          <div className="row flex-nowrap w-100 pr-4 position-relative">
            <div className="col-lg-8 pr-0">
              <section className="bg-primary">
                <div className="row">
                  <div className="col-12">
                    <div className="input-group input-md">
                      <Input className="formControl" placeholder="Group #" autoFocus />
                      <label className="formLabel">Group #</label>
                    </div>
                    <div className="input-group input-switch color-green w-auto float-right">
                      <SetStatus label="Status" />
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="input-group input-xxl">
                      <Input className="formControl" placeholder="Plan / Drug" />
                      <label className="formLabel">Plan / Drug</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group input-xl">
                      <Select className="formControl" placeholder="Group Type" defaultValue="0">
                        <Option value="0">Not Specified</Option>
                        <Option value="1">Commercial</Option>
                        <Option value="2">Commercial / Medicare</Option>
                        <Option value="3">Medicaid</Option>
                        <Option value="4">Discount Card</Option>
                        <Option value="5">Medicare Part D</Option>
                        <Option value="6">Medicare Part C</Option>
                        <Option value="7">Medicare Part B</Option>
                        <Option value="8">Military Installations</Option>
                        <Option value="9">Indian Nations</Option>
                        <Option value="10">Workers Compensation</Option>
                        <Option value="11">Other</Option>
                      </Select>
                      <label className="formLabel">Group Type</label>
                    </div>
                    <div className="input-group input-md">
                      <Input className="formControl" placeholder="Plan ID" />
                      <label className="formLabel">Plan ID</label>
                    </div>
                  </div>
                  <div className="col-12 px-0 pr-5">
                    <section className="bg-secondary mt-3 mb-0">
                      <div className="col-12">
                        <div className="input-group input-phone with-addon">
                          <div className="input-group-prepend">
                            <i className="mdi mdi-numeric-1-box"></i>
                          </div>
                          <MaskedInput
                            id="tel1"
                            className="formControl"
                            placeholder="Tel"
                            mask="111 111 1111"
                            name="tel1"
                            prefix={
                              <Tooltip title="Tel" position="top">
                                <i className="mdi mdi-phone"></i>
                              </Tooltip>
                            }
                          />
                        </div>
                        <div className="input-group input-phone with-addon">
                          <div className="input-group-prepend">
                            <i className="mdi mdi-numeric-2-box"></i>
                          </div>
                          <MaskedInput
                            id="tel2"
                            className="formControl"
                            placeholder="Tel"
                            mask="111 111 1111"
                            name="tel2"
                            prefix={
                              <Tooltip title="Tel" position="top">
                                <i className="mdi mdi-phone"></i>
                              </Tooltip>
                            }
                          />
                        </div>
                        <div className="input-group input-phone with-addon">
                          <div className="input-group-prepend">
                            <span>PA</span>
                          </div>
                          <MaskedInput
                            id="telPa"
                            className="formControl"
                            placeholder="Tel (PA)"
                            mask="111 111 1111"
                            name="telPa"
                            prefix={
                              <Tooltip title="Tel (Prior Authorization) " position="top">
                                <i className="mdi mdi-phone"></i>
                              </Tooltip>
                            }
                          />
                        </div>
                        <div className="input-group input-phone with-addon">
                          <div className="input-group-prepend">
                            <span>PA</span>
                          </div>
                          <MaskedInput
                            id="faxPa"
                            className="formControl"
                            placeholder="Fax (PA)"
                            mask="111 111 1111"
                            name="faxPa"
                            prefix={
                              <Tooltip title="Fax (Prior Authorization)" position="top">
                                <i className="mdi mdi-phone"></i>
                              </Tooltip>
                            }
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </section>
              <div className="input-group my-3 input-note">
                <Input className="formControl" placeholder="Notes" />
              </div>
            </div>
            <div className="section-divider">
              <hr axis="y"></hr>
            </div>
            <div className="col-lg-4 pl-0">
              <div className="d-flex dir-col">
                <div className="input-group input-switch input-sm">
                  <Switch
                    size="small"
                    className="formControl bg-green"
                    checkedChildren={<i className="mdi mdi-check" />}
                    unCheckedChildren={<i className="mdi mdi-close" />}
                    defaultChecked
                  />
                  <label className="formLabel">Online</label>
                </div>
                <div className="input-group input-switch input-sm">
                  <Switch
                    size="small"
                    className="formControl bg-green"
                    name="toggleBlocked"
                    checkedChildren={<i className="mdi mdi-check" />}
                    unCheckedChildren={<i className="mdi mdi-close"></i>}
                  />
                  <label className="formLabel">Blocked</label>
                </div>
                <div className="input-group input-note">
                  <Input className="formControl" placeholder="Blocked Note" />
                </div>
                <div className="input-group input-switch input-sm">
                  <Switch
                    size="small"
                    className="formControl bg-red"
                    checkedChildren={<i className="mdi mdi-check" />}
                    unCheckedChildren={<i className="mdi mdi-close" />}
                  />
                  <label className="formLabel">Stars</label>
                </div>
              </div>

              <section className="bg-secondary">
                <div className="input-group">
                  <div className="input-group-prepend" style={{ minWidth: "120px" }}>
                    <span>Max Supply</span>
                  </div>
                  <InputNumber
                    min={0}
                    max={365}
                    formatter={(value) => daysFormatter(value)}
                    parser={(value) => daysParser(value)}
                    placeholder="Day(s)"
                    onChange={handleChange}
                    className="formControl"
                  />
                </div>
                <div className="input-group">
                  <div className="input-group-prepend" style={{ minWidth: "120px" }}>
                    <span>Editable Time</span>
                  </div>
                  <InputNumber
                    min={0}
                    max={365}
                    formatter={(value) => daysFormatter(value)}
                    parser={(value) => daysParser(value)}
                    placeholder="Day(s)"
                    onChange={handleChange}
                    className="formControl"
                  />
                </div>
              </section>
              <section className="bg-primary">
                <div className="input-group d-block">
                  <Checkbox checked={checked} onChange={handleShowMsg}>
                    Show Msg.
                  </Checkbox>
                  {checked ? (
                    <>
                      <TextArea rows="5" className="mt-3"></TextArea> <TextArea rows="5" className="mt-3"></TextArea>
                    </>
                  ) : null}
                </div>
              </section>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default InsGroupDetail;
