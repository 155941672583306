import React, { useEffect, useState } from "react";
import { Tabs, DatePicker, Input, Select, Tooltip, Divider, Button, Popconfirm, Space, Table, message, Tag, Switch, Menu, Dropdown } from "antd";
import moment from "moment";
import { BarsOutlined, CheckCircleOutlined, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { binState, pcnState, groupState, eligibilityCheckState, familyMemberState } from "../../../../utils/constant/patient/atom";
import { useRecoilState } from "recoil";
import InsBinDetail from "../../Insurance/InsuranceBin/insBinDetail";
import InsPcnDetail from "../../Insurance/InsurancePcn/insPcnDetail";
import InsGroupDetail from "../../Insurance/InsuranceGroup/insGroupDetail";
import FamilyMembers from "./familyMembers";
import EligibilityChecks from "./eligibilityCheck";

// const { TabPane } = Tabs;
const { Option } = Select;

let index = 0;

function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log("blur");
}

function onFocus() {
  console.log("focus");
}

function onSearch(val) {
  console.log("search:", val);
}

function handleChange(value) {
  console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
}

const addInsData = [];

for (let i = 0; i < 10; i++) {
  addInsData.push({
    key: i,
    bin: `RysaTech ${i}`,
    pcn: `RysaTech ${i}`,
    group: `23123234${i}`,
    pbm: `23123234${i}`,
    planDrug: `RysaTech ${i}`,
  });
}

const columnsAddIns = [
  {
    title: "BIN",
    dataIndex: "bin",
    key: "bin",
    width: 120,
  },
  {
    title: `PBM`,
    dataIndex: "pbm",
    key: "pbm",
    ellipsis: {
      showTitle: false,
    },
    render: (pbm) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={pbm}>
        {pbm}
      </Tooltip>
    ),
  },
  {
    title: `PCN`,
    dataIndex: "pcn",
    key: "pcn",
    width: 120,
  },
  {
    title: `Group`,
    dataIndex: "group",
    key: "group",
    width: 120,
  },
  {
    title: `Plan / Drug`,
    dataIndex: "planDrug",
    key: "planDrug",
    ellipsis: {
      showTitle: false,
    },
    render: (planDrug) => (
      <Tooltip mouseLeaveDelay={0} placement="topLeft" title={planDrug}>
        {planDrug}
      </Tooltip>
    ),
  },
  {
    title: "Action",
    key: "action",
    className: "col-action",
    width: 120,
    render: () => (
      <Space size="middle" className="actions">
        <Tooltip title="Select " mouseLeaveDelay={0} placement="bottom">
          <Link className="action-tag-ins"></Link>
        </Tooltip>
      </Space>
    ),
  },
];




export default function PatientBilling() {
  const { items, name } = useState(0);
  const [isBin, setBin] = useRecoilState(binState);
  const [isPcn, setPcn] = useRecoilState(pcnState);
  const [isGroup, setGroup] = useRecoilState(groupState);
  const [isEligibilityCheck, setEligibilityCheck] = useRecoilState(eligibilityCheckState);
  const [isFamilyMember, setFamilyMember] = useRecoilState(familyMemberState);
  const [addMemberData, setMemberData] = useState([])

  useEffect(() => {
    async function preInfoCall() {
      let data = [];
      for (let i = 1; i <= 4; i++) {
        data.push({
          key: i,
          bin: `RysaTech ${i}`,
          pcn: `RysaTech ${i}`,
          group: `23123234${i}`,
          pbm: `23123234${i}`,
          insPrefference: `${i}`,
          planDrug: `RysaTech ${i}`,
        });
      }
      setMemberData(data);
    }

    preInfoCall();
  }, []);





  const [state, setState] = useState({
    bin: "",
    pcn: "",
    group: "",
  })
  console.log(state)

  function handleChangeSelect(value, row) {

    let index = addMemberData.findIndex((item) => item.key === row.key);
    let index2 = addMemberData.findIndex((item) => item.insPrefference == parseInt(value));
    console.log(index2, value);
    let data = [...addMemberData];
    if (index2 !== -1) {
      data[index2].insPrefference = 4;
    }
    if (index !== -1) {
      data[index].insPrefference = value;
    }

    let orgData = data.sort((a, b) => a.insPrefference - b.insPrefference)

    console.log(orgData);

    setMemberData(orgData);


  }
  const columnsAddMember = [
    {
      title: "BIN",
      dataIndex: "bin",
      key: "bin",
      width: 100,
    },
    {
      title: `PBM`,
      dataIndex: "pbm",
      key: "pbm",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (pbm) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={pbm}>
          {pbm}
        </Tooltip>
      ),
    },
    {
      title: `PCN`,
      dataIndex: "pcn",
      key: "pcn",
      width: 100,
    },
    {
      title: `Group`,
      dataIndex: "group",
      key: "group",
      width: 100,
    },
    {
      title: "Plan / Drug",
      dataIndex: "planDrug",
      key: "planDrug",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (planDrug) => (
        <Space size="middle" className="actions">
          <Tooltip mouseLeaveDelay={0} placement="topLeft" title={planDrug}>
            {planDrug}
          </Tooltip>
        </Space>
      ),
    },
    // {
    //   title: `Plan ID`,
    //   dataIndex: "planId",
    //   key: "planId",
    //   width: 100,
    // },
    {
      title: `Member ID`,
      dataIndex: "memberId",
      key: "memberId",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (memberId) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={memberId}>
          {memberId}
        </Tooltip>
      ),
    },
    {
      title: `PC`,
      dataIndex: "pc",
      key: "pc",
      width: 50,
    },
    {
      title: `CH Name`,
      dataIndex: "chName",
      key: "chName",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (chName) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={chName}>
          {chName}
        </Tooltip>
      ),
    },
    // {
    //   title: "Online",
    //   dataIndex: "online",
    //   width: 70,
    //   render: (row) => {
    //     if (row === true) {
    //       return (
    //         <Tag icon={<CheckCircleOutlined />} color="success">
    //           Yes
    //         </Tag>
    //       );
    //     } else {
    //       return (
    //         <Tag icon={<ExclamationCircleOutlined />} color="warning">
    //           No
    //         </Tag>
    //       );
    //     }
    //   },
    // },
    {
      title: `Start Date`,
      dataIndex: "startDate",
      key: "startDate",
      width: 100,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      className: "col-status",
      width: 60,
      render: () => (
        <Switch
          size="small"
          className="bg-green"
          checkedChildren={<i className="mdi mdi-check" />}
          unCheckedChildren={<i className="mdi mdi-close" />}
          defaultChecked
        />
      ),
    },
    {
      title: <Tooltip title="Insurance Prefference">IP</Tooltip>,
      width: 60,
      align: "center",
      className: "col-select",
      render: (row) => (

        <>
          <Select dropdownClassName="select-ins" value={parseInt(row.insPrefference)} onChange={(value) => handleChangeSelect(value, row)}>

            <Option value={1} className="ins-status primary" title="Primary">
              P
            </Option>
            <Option value={2} className="ins-status secondary" title="Secondary">
              S
            </Option>
            <Option value={3} className="ins-status tertiary" title="Tertiary">
              T
            </Option>
            <Option value={4} className="ins-status not-specified" title="Un Specified">
              U
            </Option>
          </Select>
        </>
      ),
    },
    {
      title: `Notes`,
      dataIndex: "notes",
      key: "notes",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (notes) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={notes}>
          {notes}
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      className: "col-action",
      width: 60,
      render: () => (
        <Space size="middle" className="actions">
          <Tooltip title="Edit" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-edit"></Link>
          </Tooltip>
          <Tooltip title="Select " mouseLeaveDelay={0} placement="bottom">
            <Link className="action-tag-ins"></Link>
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="d-none">
        <InsBinDetail />
        <InsGroupDetail />
        <InsPcnDetail />
        <EligibilityChecks />
        <FamilyMembers />
      </div>
      <div className="btn-bar">
        <div className="float-left">
          <Button
            className="btn btn-eligiblity-check"
            icon={<i className="mdi mdi-checkbox-multiple-marked-circle" />}
            onClick={() => setEligibilityCheck(true)}>
            Eligibility Check
          </Button>
          <Button className="btn btn-family-member" icon={<i className="mdi mdi-plus-circle" />} onClick={() => setFamilyMember(true)}>
            Family Member
          </Button>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-12">
          <section className="bg-primary pat-billing">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="input-group" style={{ width: "170px" }}>
                      <Select
                        autoFocus
                        showSearch
                        placeholder="BIN"
                        optionFilterProp="children"
                        // onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        onChange={(value) => {
                          setState({ ...state, bin: 1 })
                        }}
                        dropdownClassName="dropdown-menu-ins"
                        // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            {/* <Divider style={{ margin: ".5rem 0" }} />
                            <div className="btn-wrap">
                              <Button className="btn" onClick={() => setBin(true)}>
                                <i className="mdi mdi-plus-circle" /> <span>BIN</span>
                              </Button>
                            </div> */}
                          </>
                        )}>
                        <Option value="1">
                          A101284
                          <Tooltip title="Active" placement="right">
                            <small className="status active"></small>
                          </Tooltip>
                        </Option>
                        <Option value="2">
                          KMWX{" "}
                          <Tooltip title="Blocked" placement="right">
                            <small className="status blocked"></small>
                          </Tooltip>
                        </Option>
                        <Option value="3">
                          A1051465{" "}
                          <Tooltip title="InActive" placement="right">
                            <small className="status inActive"></small>
                          </Tooltip>
                        </Option>
                      </Select>
                      <label className="formLabel">BIN</label>
                      <Link className={state.bin === "" ? "input-group-append btn btn-add" : "input-group-append btn btn-edit"} onClick={() => setGroup(true)}>
                        {/* <i className="mdi mdi-pencil m-0"></i> */}
                        <i className={state.bin !== "" ? "mdi mdi-square-edit-outline" : "mdi mdi-plus-circle"}></i>
                      </Link>
                      {/* */}
                    </div>
                    <div className="input-group input-xl">
                      <Input className="formControl" placeholder="PBM" />
                      <label className="formLabel">PBM</label>
                    </div>
                    <div className="input-group input-lg">
                      <Select
                        showSearch
                        placeholder="PCN"
                        optionFilterProp="children"
                        // onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        onChange={(value) => {
                          setState({ ...state, pcn: 1 })
                        }}
                        // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            {/* <Divider style={{ margin: ".5rem 0" }} />
                            <div className="btn-wrap">
                              <Button className="btn" onClick={() => setPcn(true)}>
                                <i className="mdi mdi-plus-circle" /> <span>PCN</span>
                              </Button>
                            </div> */}
                          </>
                        )}>
                        <Option value="1">
                          A101284
                          <Tooltip title="Active" placement="right">
                            <small className="status active"></small>
                          </Tooltip>
                        </Option>
                        <Option value="2">
                          KMWX{" "}
                          <Tooltip title="Blocked" placement="right">
                            <small className="status blocked"></small>
                          </Tooltip>
                        </Option>
                        <Option value="3">
                          A1051465{" "}
                          <Tooltip title="InActive" placement="right">
                            <small className="status inActive"></small>
                          </Tooltip>
                        </Option>
                      </Select>
                      <label className="formLabel">PCN</label>
                      <Link className={state.pcn === "" ? "input-group-append btn btn-add" : "input-group-append btn btn-edit"} onClick={() => setGroup(true)}>
                        {/* <i className="mdi mdi-pencil m-0"></i> */}
                        <i className={state.pcn !== "" ? "mdi mdi-square-edit-outline" : "mdi mdi-plus-circle"}></i>
                      </Link>
                    </div>
                    <div className="input-group input-lg">
                      <Select
                        showSearch
                        placeholder="Group"
                        optionFilterProp="children"
                        onChange={(value) => {
                          setState({ ...state, group: 1 })
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            {/* <Divider style={{ margin: ".5rem 0" }} /> */}
                            {/* <div className="btn-wrap">
                              <Link className="btn" onClick={() => setGroup(true)}>
                                <i className="mdi mdi-plus-circle" /> <span>Group</span>
                              </Link>
                            </div> */}
                          </>
                        )}>
                        <Option value="1">
                          A101284
                          <Tooltip title="Active" placement="right">
                            <small className="status active"></small>
                          </Tooltip>
                        </Option>
                        <Option value="2">
                          KMWX{" "}
                          <Tooltip title="Blocked" placement="right">
                            <small className="status blocked"></small>
                          </Tooltip>
                        </Option>
                        <Option value="3">
                          A1051465{" "}
                          <Tooltip title="InActive" placement="right">
                            <small className="status inActive"></small>
                          </Tooltip>
                        </Option>
                      </Select>
                      <label className="formLabel">Group</label>
                      <Link className={state.group === "" ? "input-group-append btn btn-add" : "input-group-append btn btn-edit"} onClick={() => setGroup(true)}>
                        {/* <i className="mdi mdi-pencil m-0"></i> */}
                        <i className={state.group !== "" ? "mdi mdi-square-edit-outline" : "mdi mdi-plus-circle"}></i>
                      </Link>
                    </div>
                    <div className="input-group" style={{ width: "300px" }}>
                      <Input className="formControl" placeholder="Plan / Drug" />
                      <Tooltip title="Plan / Drug" placement="topLeft">
                        <label className="formLabel">P / D</label>
                      </Tooltip>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Tooltip title="Search" mouseLeaveDelay={0}>
                        <Button className="btn btn-icon btn-sm btn-search mr-2" icon={<SearchOutlined />} />
                      </Tooltip>
                      <Tooltip title="Link These" mouseLeaveDelay={0}>
                        <Button
                          className="btn btn-icon btn-sm btn-link m-1"
                          icon={<i className="mdi mdi-link"></i>}
                          style={{ float: "right !important" }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <Table
                  className="table-length length-five"
                  columns={columnsAddIns}
                  dataSource={addInsData}
                  pagination={false}
                  scroll={{ y: 150 }}
                  rowKey={(record) => record.uid}
                />
                {/* </TabPane> */}
                {/* <TabPane tab="Facility" key="2">
                      <PatientBillingFacility />
                    </TabPane>
                    <TabPane tab="House Charge" key="3">
                      <PatientBillingHouseCharge />
                    </TabPane> */}
                {/* </Tabs> */}
              </div>
            </div>
          </section>

          <section className="bg-primary p-0">
            {/* <div className="section-title">
              <span className="title-text">Members Info</span>
            </div> */}
            <section className="bg-secondary mb-0">
              <div className="detail-block info-bar-sm info-bar-top mt-0 my-2">
                {/* <div className="detail-actions">
                  <Link className="detail-btn btn-edit" tabIndex="-1">
                    <i className="mdi mdi-square-edit-outline"></i>
                  </Link>
                </div> */}
                <div className="detail-info">
                  <label>BIN</label>
                  <span>A123S1</span>
                </div>
                <div className="detail-info">
                  <label>PBM</label>
                  <span>A123S123123325234</span>
                </div>
                <div className="detail-info">
                  <label>PCN</label>
                  <span>A123S12</span>
                </div>
                <div className="detail-info">
                  <label>Group</label>
                  <span>A123S123123325234</span>
                </div>
                <div className="detail-info">
                  <label>Plan / Drug</label>
                  <span>Testin Plan drug</span>
                </div>
              </div>
              <div className="row align-items-center ">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-lg">
                        <Input className="formControl" placeholder="Member #" />
                        <label className="formLabel">Member #</label>
                      </div>
                      <div className="input-group input-xs  ">
                        <Input className="formControl" placeholder="PC" />
                        <Tooltip title="Person Code" placement="topLeft">
                          <label className="formLabel">PC</label>
                        </Tooltip>
                      </div>
                      <div className="input-group input-lg">
                        <Select placeholder="Realtionship">
                          <Option value="pharmacist">Father</Option>
                          <Option value="mother">Mother</Option>
                          <Option value="brother">Brother</Option>
                          <Option value="sister">Sister</Option>
                          <Option value="son">Son</Option>
                          <Option value="daughter">Daughter</Option>
                          <Option value="spouse">Spouse</Option>
                          <Option value="other">Other</Option>
                        </Select>
                        <label className="formLabel">Realtionship</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group input-lg">
                        <Input className="formControl" placeholder="CH Last Name" />
                        <Tooltip title="Card Holder Last Name" placement="topLeft">
                          <label className="formLabel">CH Last Name</label>
                        </Tooltip>
                      </div>
                      <div className="input-group input-lg">
                        <Input className="formControl" placeholder="CH First Name" />
                        <Tooltip title="Card Holder First Name" placement="topLeft">
                          <label className="formLabel">CH First Name</label>
                        </Tooltip>
                      </div>
                      <div className="input-group input-lg">
                        <Input className="formControl" placeholder="MI" />
                        <label className="formLabel">MI</label>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div className="input-group input-xl float-right ml-5">
                          <Input placeholder="Notes"></Input>
                        </div>
                        <div className="input-group input-date ml-5">
                          <DatePicker className="formControl" placeholder="MM/DD/YYYY" format="MM/DD/YYY" />
                          <label className="formLabel">Start Date</label>
                        </div>
                        <Button className="btn btn-icon btn-add ml-4 float-right">
                          <span></span>
                        </Button>
                        {/* <div className="input-group input-date">
                          <DatePicker className="formControl" placeholder="MM/DD/YYYY" format="MM/DD/YYY" />
                          <Tooltip title="Expiration Date" placement="topLeft">
                            <label className="formLabel">Exp. Date</label>
                          </Tooltip>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Table
              className="table-length length-five p-2 mt-0"
              columns={columnsAddMember}
              dataSource={addMemberData}
              pagination={false}
              scroll={{ y: 138 }}
              rowKey={(record) => record.uid}
            />
          </section>
        </div>
      </div>
    </>
  );
}
