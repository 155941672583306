import React, { useState } from "react";

import { Button, DatePicker, Input, message, Popconfirm, Select, Space, Table, Tooltip, Modal, AutoComplete } from "antd";
import { useRecoilState } from "recoil";
import { familyMemberState } from "../../../../utils/constant/patient/atom";
import SearchPatient from "Components/common/searchPatient";
import FamilyMemberTable from "./familyMemberTable";

export default function FamilyMembers() {
  const [isFamilyMember, setFamilyMember] = useRecoilState(familyMemberState);

  function cancel(e) {
    message.error("Click on No");
  }
  function confirm(e) {
    message.success("Click on Yes");
  }
  const handleOk = async (e) => {
    setFamilyMember(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setFamilyMember(false);
    message.error("Data not saved!");
  };

  const { Option } = Select;

  const onSelect = (value) => {
    console.log("onSelect", value);
  };

  console.log(setFamilyMember);

  return (
    <>
      <Modal
        width={900}
        title="Patient Search"
        visible={isFamilyMember}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <section className="bg-primary">
          <div className="row">
            <div className="col-12">
              <div className="input-group input-xl">
                {/* <SearchPatient /> */}
                <Input placeholder="Patient"></Input>
              </div>
              <div className="input-group input-date">
                <DatePicker className="formControl" format="MM/DD/YYYY" placeholder="MM/DD/YYYY" name="dateOfBirth" />
                <Tooltip title="Date of Birth">
                  <label className="formLabel">DOB</label>
                </Tooltip>
              </div>
              <div className="input-group input-lg">
                <Select value="0">
                  <Option value="0">Not-Specified</Option>
                  <Option value="1">Father</Option>
                  <Option value="2">Mother</Option>
                  <Option value="3">Brother</Option>
                  <Option value="4">Sister</Option>
                  <Option value="5">Son</Option>
                  <Option value="6">Daughter</Option>
                  <Option value="7">Spouse</Option>
                  <Option value="8">Other</Option>
                </Select>
                <label className="formLabel">Realtionship</label>
              </div>
              <Button className="btn btn-icon btn-sm btn-search float-right ml-3">
                <span></span>
              </Button>
            </div>
          </div>
        </section>
        <FamilyMemberTable />
      </Modal>
    </>
  );
}
