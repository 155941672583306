import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import PharmacyApp from "./PharmacyApp";
import * as serviceWorker from "./serviceWorker";
import { RecoilRoot } from "recoil";
import LoaderPill from "./components/common/loader";
import "./assets/css/global.min.css";
import "bootstrap/scss/bootstrap.scss";
import "./assets/css/width.min.css";
import "antd/dist/antd.css";
// import "./assets/css/icons.min.css";
// import './assets/scss/responsive.min.css';

ReactDOM.render(
  // <React.StrictMode>
  <RecoilRoot>
    <Suspense fallback={() => <LoaderPill />}>
      <PharmacyApp />
    </Suspense>
  </RecoilRoot>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
