import React, { useState } from "react";
import { Modal, Button, Popconfirm, Input, Select, Checkbox, message, Switch } from "antd";
import SetStatus from "Components/common/setStatus";
import TextArea from "antd/lib/input/TextArea";
import { pcnState } from "../../../../utils/constant/patient/atom";
import { useRecoilState } from "recoil";
import { blockedState } from "../../../../utils/constant/insurance/atom";

function InsPcnDetail() {
  const [checked, setChecked] = useState(false);
  const [isPcn, setPcn] = useRecoilState(pcnState);

  const insPcnDetailModal = () => {
    setPcn(true);
  };

  const handleOk = async (e) => {
    setPcn(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setPcn(false);
    message.error("Data not saved!");
  };

  const { Option } = Select;

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  }

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  function handleShowMsg(e) {
    if (e.target.checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }

  return (
    <>
      <Button className="btn float-right" icon={<i className="mdi mdi-plus-circle" />} onClick={() => insPcnDetailModal(true)}>
        Insurance PCN
      </Button>
      <Modal
        width={900}
        title="Add Insurance PCN"
        visible={isPcn}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <section className="m-0 p-0">
          <div className="row flex-nowrap w-100 pr-4 position-relative">
            <div className="col-lg-8 pr-0">
              <section className="bg-primary">
                <div className="section-title">
                  <div className="input-group input-md">
                    <Input className="formControl" placeholder="PCN" autoFocus />
                    <label className="formLabel">Number</label>
                  </div>
                  <div className="input-group input-switch color-green w-auto float-right">
                    <SetStatus label="Status" />
                  </div>
                </div>
              </section>
              <div className="input-group my-3 input-note">
                <Input className="formControl" placeholder="Notes" />
              </div>
              <section className="bg-primary">
                <div className="input-group">
                  <Select placeholder="Group(s)" mode="tags" onChange={handleChange} tokenSeparators={[","]}>
                    {children}
                  </Select>
                  <label className="formLabel">Group(s)</label>
                </div>
              </section>
            </div>
            <div className="section-divider">
              <hr axis="y"></hr>
            </div>
            <div className="col-lg-4 pl-0">
              <section className="bg-primary">
                <div className="d-flex dir-col">
                  <div className="input-group input-switch input-sm">
                    <Switch
                      size="small"
                      className="formControl bg-green"
                      checkedChildren={<i className="mdi mdi-check" />}
                      unCheckedChildren={<i className="mdi mdi-close" />}
                      defaultChecked
                    />
                    <label className="formLabel">Online</label>
                  </div>
                  <div className="input-group input-switch input-sm">
                    <Switch
                      size="small"
                      className="formControl bg-green"
                      name="toggleBlocked"
                      checkedChildren={<i className="mdi mdi-check" />}
                      unCheckedChildren={<i className="mdi mdi-close"></i>}
                    />
                    <label className="formLabel">Blocked</label>
                  </div>
                  <div className="input-group input-note">
                    <Input className="formControl" placeholder="Blocked Note" />
                  </div>
                  <div className="input-group input-switch input-sm">
                    <Switch
                      size="small"
                      className="formControl bg-red"
                      checkedChildren={<i className="mdi mdi-check" />}
                      unCheckedChildren={<i className="mdi mdi-close" />}
                    />
                    <label className="formLabel">Stars</label>
                  </div>
                </div>
              </section>
            </div>
            <section className="bg-primary">
              <div className="input-group d-block">
                <Checkbox checked={checked} onChange={handleShowMsg}>
                  Show Msg.
                </Checkbox>
                {checked ? (
                  <>
                    <TextArea rows="5" className="mt-3"></TextArea> <TextArea rows="5" className="mt-3"></TextArea>
                  </>
                ) : null}
              </div>
            </section>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default InsPcnDetail;
