/* eslint-disable no-unused-vars */
import "../../../assets/css/login.min.css";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isLogedIn, isLogedType, accessTokenState, refreshTokenState } from "../../../utils/constant/atom";
import LogoRysaRx from "Assets/img/logo-rysa-rx-white.png";
import { Button, Divider, Form, Input, notification, Skeleton } from "antd";
import { UserOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons";
import SiteRoute from "SiteRoutes";
import LoaderPill from "../../../components/common/loader";
import { login } from "../../../utils/methods/apiMethods";

const authArray = [
  {
    id: 1,
    name: "admin",
    email: "admin@admin.com",
    password: "123456",
    type: "ADMIN",
  },
  {
    id: 1,
    name: "user",
    email: "user@user.com",
    password: "123456",
    type: "PRIVATE",
  },
];

function LoginPage() {
  const [isLogin, setisLogIn] = useRecoilState(isLogedIn);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
  const [type, setType] = useRecoilState(isLogedType);

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [enableBtn, setEnableBtn] = useState(false);

  useEffect(() => {
    let isLogin = localStorage.getItem("isLogIn");
    let type = localStorage.getItem("type");
    console.log(isLogin);
    if (isLogin === null) {
      setisLogIn(false);
    } else {
      setisLogIn(true);
    }

    setType(type);
    setShow(true);
  }, [setType, setisLogIn]);


  async function handleSubmit(value) {
    console.log(value);

    let exist = authArray.filter((item) => item.name === value.username && item.password === value.password);

    if (exist.length > 0) {
      setLoading(true);
      localStorage.setItem("isLogIn", true);
      localStorage.setItem("type", exist[0].type);
      setType(exist[0].type);

      // setisLogIn(true);
      setLoading(false);
      if (exist[0].type === "ADMIN") {
        window.location.assign("/admin/companySearch");
      } else {
        window.location.assign("/private/processing");
      }
    } else {
      setLoading(true);
      let obj = {
        grant_type: "password",
        username: value.username,
        password: value.password,
      }

      let res = await login(obj);
      setLoading(false);

      console.log(res);

      if (res.status === false) {
        notification.error({
          message: "Error",
          description: res.message,
        });
        return;
      }
      else {
        setAccessToken(res.data.access_token);
        setRefreshToken(res.data.refresh_token);

        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("loginInfo", res.data);
        localStorage.setItem("isLogIn", true);
        localStorage.setItem("type", "ADMIN");
        setType("ADMIN");
        window.location.assign("/admin/companySearch");
      }




    }
  }
  const getPath = () => {
    console.log(isLogin);
    if (type === "ADMIN") {
      return "/admin/companySearch";
    } else {
      return "/private/processing";
    }
  };


  if (isLogin === false || isLogin === 0) {

    return (
      <div>
        {show === true ? (
          <div className="login-page">
            <div className="auth-form">
              <div className="form-wrapper">
                <div className="form-header mt-0">
                  <span className="login-icon">
                    <UserOutlined />
                  </span>
                  {/* <img src={LoginHeaderImg} width="100%" /> */}
                </div>
                <Form name="loginForm" className="login-form" onFinish={handleSubmit} >
                  <Form.Item name="username" rules={[{ required: true, message: "Please input your Username!" }]}>
                    <Input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                      size="large"

                    />
                  </Form.Item>
                  <Form.Item name="password" rules={[{ required: true, message: "Please input your Password!" }]}>
                    <Input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                      size="large"

                    />
                  </Form.Item>
                  <div className="submit-login-form">
                    <span className="icon-wrap">
                      <i className="mdi mdi-door-closed" />
                    </span>
                    <Button htmlType="submit" loading={loading} className="btn btn-login" disabled={enableBtn}>
                      Login
                    </Button>
                  </div>
                  <div className="form-footer">
                    <div className="login-more">
                      <div className="icon-wrapper pl-sm-4">
                        <i className="barcode-icon mdi mdi-barcode-scan" title="Signin with Barcode" size={3} />
                      </div>
                      <hr orientation="center" axis="y" />
                      <div className="icon-wrapper pr-sm-4">
                        <i className="biometric-icon mdi mdi-fingerprint" title="Biomatric Login" alt="Biomatric Login"></i>
                      </div>
                    </div>
                    <p className="terms-text text-center">By using this software you agree to terms and conditions of the Software License</p>
                  </div>
                </Form>
              </div>
            </div>
            <div className="fixed-logos">
              <div className="row mx-0 align-items-center">
                {/* <div className="col text-center">
                  <p className="copyright-text">
                  © 2014 <b>RYSATECH</b> - All rights reserved.
                </p>
                </div>
                <div className="col">
                  <div className="logo-rysatech">
                    <img src={LogoRysaTech} width="200px" title="RysaTech" alt="RysaTech"></img>
                    <p className="text-right">Healthcare IT Solution</p>
                  </div>
                </div> */}
                <div className="logo-rysarx w-100">
                  <img src={LogoRysaRx} title="Rysa Rx" alt="Rysa Rx"></img>
                  <p>Pharmacy Management System</p>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  } else {

    return <Redirect to={getPath()} />;
  }
}

export default LoginPage;
