/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Table, Tooltip, Select, Space, Modal, message, Popconfirm, Switch } from "antd";
import AddRolesPermission from "./components/addRolesPermissions";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { rolesDialogState, rolesVisibleState } from "../../../utils/constant/roles/atom";
import { roleSearchs, destoryRole } from "../../../utils/methods/apiMethods";

export default function EmployeeRollSearch({ isRolesDialog, closeSidebarToggle }) {
  const [isRolesModal, setRolesDialog] = useState(rolesDialogState);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [name, setName] = useState("");
  const [editVisible, setEditVisible] = useRecoilState(rolesVisibleState);
  const [id, setID] = useState("");

  const handleCancel = (e) => {
    closeSidebarToggle("");
  };

  const searchRole = async () => {
    let obj = {
      createdBy: 0,
      createdDate: "2021-05-29T16:11:36.613Z",
      groupId: 0,
      modifiedBy: 0,
      roleName: name,
      secRoleId: 0,
      storeId: 0,
    };

    setLoading(true);
    let res = await roleSearchs("roleName", obj);
    console.log(res);
    if (res.status === false) {
      setLoading(false);
      return;
    }
    if (res.data.status === "SUCCESS") {
      setRoles(res.data.object);
      setLoading(false);
    } else {
      setRoles([]);
      setLoading(false);
    }
  };
  const editRole = async (id) => {
    setID(id);
    setEditVisible(true);
  };
  const deleteRole = async (id) => {
    setDelLoading(true);
    let res = await destoryRole(id);
    console.log(res);
    if (res.data.status === "SUCCESS") {
      let index = roles.findIndex((x) => parseInt(x.secRoleId) === parseInt(id));

      const rowArr = [...roles];
      rowArr.splice(index, 1);
      setRoles(rowArr);
      setDelLoading(false);
    } else {
      setDelLoading(false);
    }
  };

  const columns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 60,
      render: (row) => (
        <Popconfirm title="Ok to change status?" okText="Yes" cancelText="No">
          {/* onConfirm={() => deleteRole(row.secRoleId)} */}
          <Switch
            size="small"
            className="bg-green"
            checkedChildren={<i className="mdi mdi-check" />}
            unCheckedChildren={<i className="mdi mdi-close" />}
            defaultChecked
          />
        </Popconfirm>
      ),
    },
    {
      title: "Actions",
      key: "action",
      width: "100px",
      render: (row) => (
        <Space size="middle" className="actions">
          <Tooltip title="Edit" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-edit" onClick={() => editRole(row.secRoleId)}></Link>
          </Tooltip>
          <Tooltip title="Copy & Make" mouseLeaveDelay={0} placement="bottom">
            <Link className="action-duplicate"></Link>
          </Tooltip>
        </Space>
      ),
    },
  ];
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      searchRole();
    }
  }
  return (
    <Modal
      width={700}
      title="Role(s) Search"
      visible={isRolesDialog}
      onCancel={handleCancel}
      closable={false}
      maskClosable={false}
      footer={
        <div className="btn-bar">
          <div className="float-left">
            <AddRolesPermission editVisible={editVisible} setEditVisible={setEditVisible} id={id} setID={setID} />
          </div>
          <div className="float-right">
            <Button className="btn btn-close" onClick={handleCancel}>
              <span></span>
            </Button>
          </div>
        </div>
      }>
      <div className="col-12 mx-auto">
        <section className="filter-bar">
          <div className="input-group input-xl">
            <Input
              className="formControl"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Role Name"
              size="large"
              onKeyDown={handleKeyDown}
              autoFocus
            />
            <label className="formLabel">Role Name</label>
          </div>
          <div className="filter-actions">
            <Tooltip title="Search" mouseLeaveDelay={0}>
              <Button className="btn btn-search" icon={<SearchOutlined />} loading={loading} onClick={() => searchRole()} />
            </Tooltip>
            <Button className="btn btn-clear-all" onClick={() => setName("")}>
              Clear All
            </Button>
          </div>
        </section>
        <Table size="small" columns={columns} dataSource={roles} pagination={false} scroll={{ y: "calc(100vh - 325px)" }} />
        <div className="btn-bar">
          <div className="float-right">{/* <AddRolesPermission /> */}</div>
        </div>
      </div>
    </Modal>
  );
}
