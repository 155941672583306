import { notification } from "antd";
import * as ApiRoutes from "ApiRoutes";
import Axios from "axios";
import $ from "jquery";

// Store Registration

export async function getCompanyRegistration() {
  try {
    const response = await Axios.get(ApiRoutes.getCompanyRegistration);
    return { status: true, data: response, message: "Data Fetch" };
  } catch (error) {
    return { status: false, message: "" };
  }
}

// Company Reg

export async function createCompany(state) {
  try {
    const response = await Axios.post(ApiRoutes.storeCompanyRegistration, {
      ...state,
    });

    return {
      status: true,
      data: response.data,
      message: "Company Create Successfully",
    };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function createEmployee(state) {
  try {
    const response = await Axios.post(ApiRoutes.empSave, {
      ...state,
    });

    return {
      status: true,
      data: response.data,
      message: "Employee Create Successfully",
    };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function updateEmployee(empID, state) {
  try {
    const response = await Axios.post(ApiRoutes.empUpdate + `?employeeID=${empID}`, {
      ...state,
    });

    return {
      status: true,
      data: response.data,
      message: "Employee Create Successfully",
    };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
//companyDelete
export async function deleteCompany(state) {
  try {
    const response = await Axios.post(ApiRoutes.companyDelete, {
      ...state,
    });

    return {
      status: true,
      data: response.data,
      message: "Company Delete Successfully",
    };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}

// Company Search

export async function companySearch(state) {
  try {
    const response = await Axios.post(ApiRoutes.storeCompanySearch, {
      ...state,
    });

    return { status: true, data: response.data, message: "Company Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}

//getCompany
export async function getCompany(id) {
  try {
    const response = await Axios.get(ApiRoutes.getCompany(id));

    return { status: true, data: response.data, message: "Company Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function getEmployee(id) {
  try {
    const response = await Axios.get(ApiRoutes.editEmployee(id));

    return { status: true, data: response.data, message: "Employee Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function getStore(id) {
  try {
    const response = await Axios.post(ApiRoutes.getStoreByID(id));

    return { status: true, data: response.data, message: "Store Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function updateCompany(id, state) {
  try {
    const response = await Axios.put(ApiRoutes.getCompany(id), {
      ...state,
    });

    return { status: true, data: response.data, message: "Company Update" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}







// CITY

export async function getCity(zipcode) {
  try {
    const response = await Axios.post(ApiRoutes.getCityByZip(zipcode));
    return { status: true, data: response.data, message: "Data Fetch" };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
}

// FETCH STORE
export async function fetchStores() {
  try {
    const response = await Axios.get(ApiRoutes.getStore);
    return { status: true, data: response, message: "Data Fetch" };
  } catch (error) {
    return { status: false, message: "" };
  }
}

// STORE SEARCH

export async function storeSearch(state) {
  try {
    const response = await Axios.post(ApiRoutes.storeSearch, {
      ...state,
    });

    return { status: true, data: response.data, message: "Store Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function empSearch(state) {
  try {
    const response = await Axios.post(ApiRoutes.empSearch, {
      ...state,
    });

    return { status: true, data: response.data, message: "Emp Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}

// STORE CREATE

export async function createStore(state) {
  try {
    const response = await Axios.post(ApiRoutes.saveStore, {
      ...state,
    });

    return {
      status: true,
      data: response.data,
      message: "Store Create Successfully",
    };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}

export async function updateStore(state) {
  try {
    const response = await Axios.post(ApiRoutes.updateStore, {
      ...state,
    });

    return {
      status: true,
      data: response.data,
      message: "Store Update Successfully",
    };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function storeStatus(state) {
  try {
    const response = await Axios.post(ApiRoutes.changeStoreStatus, {
      ...state,
    });

    return {
      status: true,
      data: response.data,
      message: "Store Status Change Successfully",
    };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}

export async function fetchPermission() {
  try {
    const response = await Axios.post(ApiRoutes.getAllPermission);
    return { status: true, data: response, message: "Data Fetch" };
  } catch (error) {
    return { status: false, message: "" };
  }
}


export async function storeRole(state) {
  try {
    const response = await Axios.post(ApiRoutes.storeRole, {
      ...state,
    });

    return { status: true, data: response.data, message: "Role Saved" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function updateRole(state) {
  try {
    const response = await Axios.post(ApiRoutes.updateRole, {
      ...state,
    });

    return { status: true, data: response.data, message: "Role Update" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function roleSearchs(role, obj) {
  try {
    const response = await Axios.post(ApiRoutes.searchRole(role), obj);

    return { status: true, data: response.data, message: "Role Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function editRole(role) {
  try {
    const response = await Axios.post(ApiRoutes.editRole(role));

    return { status: true, data: response.data, message: "Role Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function destoryRole(roleID) {
  try {
    const response = await Axios.post(ApiRoutes.deleteRole(roleID, 0));

    return { status: true, data: response.data, message: "Role Delete" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}


export async function fetchStates() {
  try {
    const response = await Axios.post(ApiRoutes.allState);

    return { status: true, data: response.data, message: "State Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function fetchCity(object) {
  try {
    const response = await Axios.post(ApiRoutes.searchCity, {

      cityName: object.cityName,
      stateId: object.stateId
    });

    return { status: true, data: response.data, message: "Country Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}
export async function fetchState(object) {
  try {
    const response = await Axios.post(ApiRoutes.searchState, {
      ...object


    });

    return { status: true, data: response.data, message: "Country Fetch" };
  } catch (e) {
    if (e.response && e.response.data) {
      displayErrors(e.response.data.message);
    }
    return { status: false, message: e.response.data.message };
  }
}

//  Login


export async function login(state) {


  var form = new FormData();
  form.append("grant_type", "password");
  form.append("username", state.username);
  form.append("password", state.password);



  let isAuthenticated = await Axios.post("https://dev-ec2.ikonrx.com/oauth/token", form,

    {

      headers: {
        'Authorization': `Basic Y2xpZW50YXBwOjEyMzQ1Ng==`,
        "Content-Type": "multipart/form-data"

      },


    })
    .then((response) => {
      return { status: true, data: response.data };
    })
    .catch((error) => {
      let data = error.response.status;

      console.log(data, error.response);
      if (data === 401) {
        return { status: false, message: "Unauthenticated" };
      } else {
        return { status: false, message: error.response.data.error_description };
      }
    });

  return isAuthenticated;






}






function displayErrors(error) {
  notification.error({
    message: "Error",
    description: error,
  });
}
