import React from "react";
import moment from "moment";
import { Popconfirm, Space, Switch, Table, Tooltip, Select, message } from "antd";
import { Link } from "react-router-dom";

export default function FamilyMemberTable() {
  function cancel(e) {
    message.error("Click on No");
  }
  function confirm(e) {
    message.success("Click on Yes");
  }
  const { Option } = Select;

  const onSelect = (value) => {
    console.log("onSelect", value);
  };
  const columnsFamilyMembers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={name}>
          {<span>lastName, firstName</span>}
        </Tooltip>
      ),
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
      className: "col-date",
      width: 90,
      render: (row) => <span>{moment(row).format("MM/DD/YYYY")}</span>,
    },
    {
      title: "Cell",
      dataIndex: "cell",
      key: "cell",
      className: "col-tcf",
      width: 90,
    },

    {
      title: `Address`,
      dataIndex: "address",
      key: "address",
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip mouseLeaveDelay={0} placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: `Relation`,
      dataIndex: "relation",
      key: "relation",
      className: "col-select",
      width: 90,
      render: (row) => (
        <Select className="table-select-option" defaultValue="0">
          <Option value="0">Not-Specified</Option>
          <Option value="1">Father</Option>
          <Option value="2">Mother</Option>
          <Option value="3">Brother</Option>
          <Option value="4">Sister</Option>
          <Option value="5">Son</Option>
          <Option value="6">Daughter</Option>
          <Option value="7">Spouse</Option>
          <Option value="8">Other</Option>
        </Select>
      ),
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   align: "center",
    //   className: "col-status",
    //   width: 60,
    //   render: () => (
    //     // <Popconfirm title="Ok to change status?" onConfirm={confirm} onCancel={cancel} okText="Yes" cancelText="No" placement="topRight">
    //     <Switch
    //       size="small"
    //       className="bg-green"
    //       checkedChildren={<i className="mdi mdi-check" />}
    //       unCheckedChildren={<i className="mdi mdi-close" />}
    //       defaultChecked
    //     />
    //     // </Popconfirm>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      align: "left",
      className: "col-action",
      width: 70,
      render: () => (
        <Space size="middle" className="actions">
          <Popconfirm title="Are you sure delete this data?" onConfirm={confirm} onCancel={cancel} okText="Yes" cancelText="No">
            <Tooltip title="Delete" mouseLeaveDelay={0} placement="bottom">
              <Link className="action-delete"></Link>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const familyMemberData = [];
  for (let i = 0; i < 5; i++) {
    familyMemberData.push({
      key: i,
      name: `RysaTech ${i}`,
      dob: `RysaPharmacy ${i}`,
      cell: `111 111 111${i}`,
      tel: `12-10-197${i}`,
      relation: "Mother",
    });
  }
  return (
    <>
      <Table
        className="table-length length-three"
        columns={columnsFamilyMembers}
        dataSource={familyMemberData}
        pagination={false}
        scroll={{ y: 68 }}
        rowKey={(record) => record.uid}
      />
    </>
  );
}
