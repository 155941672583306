import React from "react";
import { MaskedInput } from "antd-mask-input";
import { Input, Tooltip } from "antd";

const ContactInfo = (props) => {
  const state = props.state;
  const handleChange = props.handleChange;
  const type = props.type;
  return (
    <>
      <div className="contact-info-address">
        <div className="input-group input-address">
          <Input
            id={`addressLine1` + type}

            className="formControl"
            placeholder="Address"
            value={state.addressLine1}
            onChange={handleChange}
            name="addressLine1"
          />
          <label className="formLabel">Address</label>
        </div>
        <div className="input-group input-suite">
          <Input
            id={`addressLine2` + type}
            className="formControl"
            name="addressLine2"
            value={state.addressLine2}
            onChange={handleChange}
            placeholder="Suite #"

          />
          <label className="formLabel">Suite #</label>
        </div>
        <div className="input-group input-city">
          <Input
            id={`city` + type}
            className="formControl"
            name="city"
            value={state.cityName}
            onChange={handleChange}
            placeholder="City"
            disabled


          />
          <label className="formLabel">City</label>
        </div>
        <div className="input-group input-state">
          <MaskedInput
            id={`state` + type}
            className="formControl"
            name="state"
            value={state.state}
            onChange={handleChange}
            placeholder="St"
            disabled
            mask="AA"


          />
          <label className="formLabel">St</label>
        </div>
        <div className="input-group input-zip">
          <MaskedInput
            id={`zip` + type}
            className="formControl"
            name="zipCode"
            value={state.zipCode}
            onChange={handleChange}
            placeholder="Zip"
            mask="11111"

          />
          <label className="formLabel">Zip</label>
        </div>
        <div className="input-group input-ext">
          <MaskedInput className="formControl" placeholder="+4"
            name="zipExt"
            value={state.zipExt}
            onChange={handleChange}
            mask="1111" autoComplete="off" />
          <label className="formLabel">+4</label>
        </div>
      </div>
      {/* {state.isAddress === true ? (
        <div className="contact-info-more">
          <div className="input-group input-phone">
            <MaskedInput
              id={`businessPhone` + type}
              className="formControl"
              placeholder="Cell"
              mask="111 111 1111"
              name="businessPhone"
              value={state.cell}
              onChange={handleChange}
              prefix={
                <Tooltip title="Cell" position="top">
                  <i className="mdi mdi-cellphone-iphone"></i>
                </Tooltip>
              }
            />
          </div>
          <div className="input-group input-phone">
            <MaskedInput
              id={`telPhone` + type}
              className="formControl"
              placeholder={!state.isPatient ? "Tel" : "Home Tel"}
              mask="111 111 1111"
              name="telPhone"
              value={state.telPhone}
              onChange={handleChange}
              prefix={
                <Tooltip title={!state.isPatient ? "Tel" : "Home Tel"} position="top">
                  <i className="mdi mdi-phone"></i>
                </Tooltip>
              }
            />
          </div>
          {state.isWorkTel === true ? (
            <>
              <div className="input-group input-phone">
                <MaskedInput
                  id={`workTel` + type}
                  className="formControl"
                  placeholder="Work Tel"
                  mask="111 111 1111"
                  name="workTel"
                  value={state.workTel}
                  onChange={handleChange}
                  prefix={
                    <Tooltip title="Work Tel" position="top">
                      <i className="mdi mdi-phone"></i>
                    </Tooltip>
                  }
                />
              </div>
              <div className="input-group input-phoneExt">
                <MaskedInput
                  id={`workTelExt` + type}
                  className="formControl"
                  placeholder="Ext."
                  mask="11111"
                  name="workTelExt"
                  value={state.workTelExt}
                  onChange={handleChange}
                />
              </div>
            </>
          ) : (
            ""
          )}

          <div className="input-group input-phone">
            <MaskedInput
              id={`fax` + type}
              className="formControl"
              placeholder="Fax"
              mask="111 111 1111"
              name="fax"
              value={state.fax}
              onChange={handleChange}
              prefix={
                <Tooltip title="Fax" position="top">
                  <i className="mdi mdi-fax"></i>
                </Tooltip>
              }
            />
          </div>
          <div className="input-group input-email">
            <Input
              className="formControl"
              placeholder="eMail"
              name="email"
              value={state.email}
              id={`email` + type}
              onChange={handleChange}
              prefix={
                <Tooltip title="Email" position="top">
                  <i className="mdi mdi-email"></i>
                </Tooltip>
              }
            />
          </div>
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default ContactInfo;
