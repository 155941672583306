const { atom } = require("recoil");

export const address = atom({
  key: "address",
  default: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    cityId: 0,
    isAddress: true,
    isWorkPhone: false,
  },
});

export const prescriberClinicDetailState = atom({
  key: "prescriberClinicDetailState",
  default: false,
});

export const prescriberDetailState = atom({
  key: "prescriberDetailState",
  default: false,
});

export const prescriberClinicSearchState = atom({
  key: "prescriberClinicSearchState",
  default: false,
});

export const prescriberClinicInfoState = atom({
  key: "prescriberClinicInfoState",
  default: false,
});
