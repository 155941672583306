import React from "react";
import { Modal, Button, Select, InputNumber } from "antd";
import { amountFormatter, amountParser } from "../../../../utils/methods/universal";
import { billingDetailState } from "../../../../utils/constant/processing/atom";
import { useRecoilState } from "recoil";
import InsBillingDetail from "./insBillingDetail";

export default function BillingDetail() {
  const [isBillingDetail, setBillingDetail] = useRecoilState(billingDetailState);

  const billingDetailModal = () => {
    setBillingDetail(true);
  };
  const handleOk = async (e) => {
    setBillingDetail(false);
  };

  const handleCancel = async (e) => {
    setBillingDetail(false);
  };

  const { Option } = Select;

  return (
    <>
      <Modal
        style={{ top: 20 }}
        width={1000}
        className="modal-claim-detail"
        title="Claim Details"
        visible={isBillingDetail}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-right">
              <Button className="btn" onClick={handleCancel} autoFocus>
                Data Review
              </Button>
              <Button className="btn btn-print" onClick={handleCancel}>
                Print Label
              </Button>
              <InputNumber className="input-sm" value="1"></InputNumber>
              <Button className="btn btn-print" onClick={handleCancel}>
                Print Label & Monograph
              </Button>
              <Button className="btn btn-close" onClick={handleCancel}>
                <span></span>
              </Button>
            </div>
          </div>
        }>
        <section>
          <section className="mt-0 bg-ins pri">
            <div className="row">
              <div className="col-12 mx-auto">
                <InsBillingDetail title="Primary Insurance" />
              </div>
            </div>
          </section>
          <section className="mt-0 bg-ins sec">
            <div className="row">
              <div className="col-12 mx-auto">
                <InsBillingDetail title="Secondary Insurance" />
              </div>
            </div>
          </section>
          <section className="mt-0 bg-ins tri">
            <div className="row">
              <div className="col-12 mx-auto">
                <InsBillingDetail title="Tertiary Insurance" />
              </div>
            </div>
          </section>
          <section className="info-bar bg-lightpurple info-bar-sm info-bar-bottom mt-0">
            <div className="info-block amount">
              <label>App. Amt.</label>
              <span>0.00</span>
            </div>
            <div className="info-block amount">
              <label>Paid Amt.</label>
              <span>0.00</span>
            </div>
            <div className="info-block amount">
              <label>Copay</label>
              <span>0.00</span>
            </div>
            <div className="info-block amount">
              <label>Cost</label>
              <span>0.00</span>
            </div>
            <div className="info-block amount">
              <label>Profit</label>
              <span>0.00</span>
            </div>
          </section>
        </section>
      </Modal>
    </>
  );
}
