import React from "react";
import { Input, Select, Tooltip, InputNumber, Radio, Checkbox, Button, Modal, message, Popconfirm, Switch } from "antd";
import { MaskedInput } from "antd-mask-input";
import TextArea from "antd/lib/input/TextArea";
import ThumbnailImg from "../../../../assets/img/thumbnail-images.jpg";
import LinkVendor from "./linkVendors";
import { amountFormatter, amountParser, percentFormatter, percentParser } from "../../../../utils/methods/validation";
import { groupNdcState, auxillaryLabelsState } from "../../../../utils/constant/product/atom";
import { useRecoilState } from "recoil";
import GroupNdc from "./groupNdc";
import AuxillaryLabels from "./auxillaryLabels";
import { drugDetailState } from "../../../../utils/constant/drug/atom";
import PatientPriceCodes from "./proDrugPriceCode";

export default function ProductDrugDetail() {
  const state = {
    checked: true,
    disabled: false,
  };

  const { Option } = Select;
  const [isGroupNdc, setGroupNdc] = useRecoilState(groupNdcState);
  const [isAuxillaryLabels, setAuxillaryLabels] = useRecoilState(auxillaryLabelsState);
  const [isDrugDetailModal, setDrugDetailModal] = useRecoilState(drugDetailState);

  const drugDetailModal = () => {
    setDrugDetailModal(true);
  };
  const handleOk = async (e) => {
    setDrugDetailModal(false);
    message.success("Data saved successfully!");
  };

  const handleCancel = async (e) => {
    setDrugDetailModal(false);
    message.error("Data not saved.");
  };

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  }

  function onChange(value) {
    console.log("changed", value);
  }

  const toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  const toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };

  return (
    <>
      <div className="d-none">
        <GroupNdc />
        <AuxillaryLabels />
      </div>
      <Modal
        width={1650}
        style={{ top: "30px" }}
        title="Add Drug"
        visible={isDrugDetailModal}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        // keyboard={false}
        footer={
          <div className="btn-bar">
            <div className="float-left">
              <Button className="btn">Reciept Inventory Report</Button>
              <Button className="btn">Dispensing Report</Button>
              <Button className="btn btn-print">Print Monograph</Button>
            </div>
            <div className="float-right">
              <Button className="btn btn-save" onClick={handleOk}>
                <span></span>
              </Button>
              <Popconfirm title="Save & Close?" onConfirm={handleOk} onCancel={handleCancel} okText="Save & Close" cancelText="Discard">
                <Button className="btn btn-close">
                  <span></span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        }>
        <section className="info-bar d-none">
          <div className="info-block">
            <label className="label-icon">
              <Tooltip title="Product Name" placement="bottomLeft">
                <i className="mdi mdi-pill"></i>
              </Tooltip>
            </label>
            <span>Amoxicillin Oral Tablet 87</span>
          </div>
          <div className="info-block">
            {/* <label>
                <Tooltip title="Strength" placement="bottomLeft">
                  <i className="mdi mdi-pharmacy"></i>
                </Tooltip>
              </label> */}
            <span>250-62.5mG/5mL</span>
          </div>
          <div className="info-block">
            {/* <label>
                <Tooltip title="Universal product Code" placement="bottomLeft">
                  <i>Form</i>
                </Tooltip>
              </label> */}
            <span>SUS</span>
          </div>
          <div className="info-block">
            {/* <label>
                <Tooltip title="Pkg. Size" placement="bottomLeft">
                  <i className="mdi mdi-package-variant"></i>
                </Tooltip>
              </label> */}
            <span>150.25</span>
          </div>
          <div className="info-block">
            <label>
              <Tooltip title="National product Code" placement="bottomLeft">
                <i>NDC</i>
              </Tooltip>
            </label>
            <span>54321 4321 21</span>
          </div>
          <div className="info-block">
            <label>
              <Tooltip title="National product Code" placement="bottomLeft">
                <i>UPC</i>
              </Tooltip>
            </label>
            <span>54321432121</span>
          </div>
        </section>
        <section className="product-detail my-0 p-0">
          <div className="row flex-nowrap w-100 pr-4 position-relative">
            <div className="col-lg-5 pr-0">
              <section className="bg-primary mb-0">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="input-group input-upc">
                      <InputNumber className="formControl" placeholder="UPC"></InputNumber>
                      <label className="formLabel">UPC</label>
                    </div>
                    <div className="input-group input-upc">
                      <InputNumber className="formControl" placeholder="Sub UPC"></InputNumber>
                      <label className="formLabel">Sub UPC</label>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-group input-ndc required">
                      <MaskedInput className="formControl" placeholder="NDC" mask="11111-1111-11" />
                      <label className="formLabel">NDC</label>
                    </div>
                    <div className="input-group input-ndc">
                      <MaskedInput className="formControl" placeholder="NDC" mask="11111-1111-11" />
                      <label className="formLabel">Sub NDC</label>
                    </div>
                    {/* <div className="input-group input-group-check float-right">
                    <Checkbox className="formControl">OTC/Rx Use</Checkbox>
                  </div> */}
                  </div>
                  <div className="col-lg-3">
                    <div className="d-flex justify-content-end">
                      <div className="input-group input-md">
                        <InputNumber precision={2} className="formControl" placeholder="0.00" disabled />
                        <label className="formLabel">On Hand Qty.</label>
                      </div>
                      <Button className="btn" tabIndex="-1">
                        PI
                      </Button>
                    </div>
                  </div>
                  <section className="px-0 py-2 my-2" style={{ background: "#e2ecff" }}>
                    <div className="d-flex flex-wrap">
                      <div className="col-12 d-flex">
                        <div className="input-group required">
                          <Input className="formControl" placeholder="Name" />
                          <label className="formLabel">Name</label>
                        </div>
                      </div>
                      <div className="col-12 d-block">
                        <div className="input-group input-stg">
                          <Input className="formControl" placeholder="Strength" />
                          <label className="formLabel">Strength</label>
                        </div>
                        <div className="input-group input-form">
                          <Select showSearch className="formControl" placeholder="Form">
                            <Option value="0">Form</Option>
                          </Select>
                          <label className="formLabel">Form</label>
                        </div>
                        <div className="input-group input-pkg">
                          <InputNumber precision={2} className="formControl" placeholder="0.00" />
                          <label className="formLabel">Pkg. Size</label>
                        </div>
                        <div className="input-group input-sm float-right">
                          <Select showSearch className="formControl" placeholder="UOM">
                            <Option value="0">Select UOM</Option>
                          </Select>
                          <Tooltip title="Unit of Measure">
                            <label className="formLabel">UOM</label>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="col-12 d-flex">
                        <div className="input-group w-100">
                          <Input className="formControl" placeholder="Generic Name" />
                          <label className="formLabel">Generic Name</label>
                        </div>
                        <div className="input-group input-lg float-right">
                          <Input className="formControl" placeholder="Generic For" />
                          <label className="formLabel">Generic For</label>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                      <div className="input-group input-group-check">
                        <Checkbox className="formControl">Generic Available</Checkbox>
                      </div>
                    </div> */}
                      </div>
                      <div className="col-12 mt-1">
                        <div className="input-group input-xl">
                          <Input className="formControl" placeholder="Manufacturer" />
                          <label className="formLabel">Manufacturer</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-1">
                      <div className="input-group input-switch w-auto">
                        <Switch
                          size="small"
                          className="formControl bg-green"
                          checkedChildren={<i className="mdi mdi-check" />}
                          unCheckedChildren={<i className="mdi mdi-close" />}
                          defaultChecked
                        />
                        <label className="formLabel">Autofill</label>
                      </div>
                      <div className="input-group input-switch w-auto">
                        <Switch
                          size="small"
                          className="formControl bg-green"
                          checkedChildren={<i className="mdi mdi-check" />}
                          unCheckedChildren={<i className="mdi mdi-close" />}
                          defaultChecked
                        />
                        <label className="formLabel">Prefered</label>
                      </div>
                      <div className="input-group input-switch w-auto">
                        <Switch
                          size="small"
                          className="formControl bg-green"
                          checkedChildren={<i className="mdi mdi-check" />}
                          unCheckedChildren={<i className="mdi mdi-close" />}
                          defaultChecked
                        />
                        <label className="formLabel">Stock</label>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div className="input-group input-switch w-auto">
                          <Switch
                            size="small"
                            className="formControl bg-green"
                            checkedChildren={<i className="mdi mdi-check" />}
                            unCheckedChildren={<i className="mdi mdi-close" />}
                            defaultChecked
                          />
                          <label className="formLabel">Vaccine</label>
                        </div>
                        <div className="input-group input-sm">
                          <div className="input-group-prepend">
                            <Checkbox></Checkbox>
                          </div>
                          <InputNumber precision={2} className="formControl" placeholder="0.00" disabled />
                          <label className="formLabel">Disp. Pkg. Size</label>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="px-0 py-2 m-0" style={{ background: "#ffe2fd" }}>
                    <div className="d-flex flex-wrap">
                      <div className="col-lg-12">
                        {/* <div className="input-group input-md">
                      <Select className="formControl" placeholder="Item Status">
                        <Option value="0">Select Item Status</Option>
                      </Select>
                      <label className="formLabel">Item Status</label>
                    </div> */}
                        <div className="input-group input-sm">
                          <Select className="formControl" defaultValue="0">
                            <Option value="0">Generic</Option>
                            <Option value="1">Brand</Option>
                          </Select>
                          <Tooltip title="Brand / Generic" placement="topRight">
                            <label className="formLabel">B/G</label>
                          </Tooltip>
                        </div>
                        <div className="input-group input-md">
                          <Select className="formControl" placeholder="Drug Type" defaultValue="P-Prescription">
                            <Option value="0">P-Prescription</Option>
                          </Select>
                          <label className="formLabel">Drug Type</label>
                        </div>
                        <div className="input-group input-md">
                          <Select className="formControl" placeholder="Select Type">
                            <Option value="0"></Option>
                          </Select>
                          <label className="formLabel">Drug Class</label>
                        </div>
                      </div>

                      <div className="col-12 mt-2">
                        <div className="input-group input-sm">
                          <Tooltip title="Unique Physician Identification Number" placement="topLeft">
                            <label className="formLabel">Upin</label>
                          </Tooltip>
                          <Input
                            className="formControl"
                            placeholder="Upin"
                            // prefix={
                            //   <Tooltip title="Unique Physician Identification Number" placement="topLeft">
                            //     <span>Upin - </span>
                            //   </Tooltip>
                            // }
                          />
                        </div>
                        <div className="input-group input-sm">
                          <Input
                            className="formControl"
                            placeholder="HCPCS"
                            // prefix={
                            //   <Tooltip title="Healthcare Common Procedure Coding System" placement="topLeft">
                            //     <span>HCPCS - </span>
                            //   </Tooltip>
                            // }
                          />
                          <Tooltip title="Healthcare Common Procedure Coding System" placement="topLeft">
                            <label className="formLabel">HCPCS</label>
                          </Tooltip>
                        </div>
                        <div className="float-right">
                          <Button className="btn btn-bbw">BBW</Button>
                          <div className="input-group input-switch w-auto">
                            <Switch
                              size="small"
                              className="formControl bg-green"
                              checkedChildren={<i className="mdi mdi-check" />}
                              unCheckedChildren={<i className="mdi mdi-close" />}
                              defaultChecked
                            />
                            <label className="formLabel">HR Med</label>
                          </div>
                        </div>

                        {/* <div className="input-group input-lg">
                      <Input
                        className="formControl"
                        prefix={
                          <Tooltip title="Current Procedural Terminology" placement="topLeft">
                            <span>CPT - </span>
                          </Tooltip>
                        }
                      />
                    </div> */}
                      </div>
                    </div>
                  </section>
                  <div className="col-12 mt-2">
                    <div className="input-group with-addon width-50">
                      <div className="input-group-prepend">
                        <Checkbox></Checkbox>
                      </div>
                      <Input className="formControl" placeholder="Default Sig Code" />
                      <label className="formLabel">Default Sig Code</label>
                    </div>
                    {/* <div className="input-group input-lg float-right">
                      <Input className="formControl" placeholder="Alternate Mono NDC" />
                      <label className="formLabel">Alternate Mono NDC</label>
                    </div> */}
                    <div className="input-group width-100">
                      <Input className="formControl" placeholder="SIG Code" disabled />
                      <label className="formLabel">SIG Code</label>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="input-group width-100">
                      <TextArea className="formControl input-notes" placeholder="Notes" id="notecontact" name="note" rows={1} />
                      <label className="formLabel">Notes</label>
                    </div>
                    {/* <div className="input-group width-100">
                  <TextArea className="formControl input-notes" placeholder="ICD Notes" id="notecontact" name="note" rows={2} />
                  <Tooltip title="International Classification of Disease" placement="topLeft">
                    <label className="formLabel">ICD Notes</label>
                  </Tooltip>
                </div> */}
                  </div>
                </div>
              </section>
              <div className="more-option d-none">
                <Button onClick={() => setGroupNdc(true)}>Group NDCs</Button>
                <Button onClick={() => setAuxillaryLabels(true)}>Auxillary Labels</Button>
              </div>
              <LinkVendor />
            </div>
            <div className="section-divider">
              <hr axis="y"></hr>
            </div>
            <div className="col-lg-7 pl-0">
              <div className="row">
                <div className="col-lg-8">
                  <section className="bg-pricing bg-primary">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-group input-amount w-100">
                          <InputNumber
                            className="formControl"
                            placeholder="0.00"
                            min={0}
                            formatter={(value) => amountFormatter(value)}
                            parser={(value) => amountParser(value)}
                            precision={2}
                            onChange={onChange}
                          />
                          <Tooltip title="Average Wholesale Price">
                            <label className="formLabel">AWP</label>
                          </Tooltip>
                        </div>
                        <div className="input-group input-amount">
                          <InputNumber
                            className="formControl"
                            placeholder="0.00"
                            min={0}
                            formatter={(value) => amountFormatter(value)}
                            parser={(value) => amountParser(value)}
                            precision={2}
                            onChange={onChange}
                          />
                          <Tooltip title="Unit - Average Wholesale Price">
                            <label className="formLabel">Unit AWP</label>
                          </Tooltip>
                        </div>
                        <div className="input-group input-group-check">
                          <Checkbox>Auto update</Checkbox>
                        </div>
                        <div className="input-group input-percent float-right">
                          <InputNumber
                            className="formControl"
                            placeholder="Surcharge"
                            min={0}
                            max={100}
                            formatter={(value) => percentFormatter(value)}
                            parser={(value) => percentParser(value)}
                          />
                          <label className="formLabel">Surcharge</label>
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="input-group input-amount">
                          <InputNumber
                            className="formControl"
                            placeholder="0.00"
                            min={0}
                            formatter={(value) => amountFormatter(value)}
                            parser={(value) => amountParser(value)}
                            precision={2}
                          />
                          <label className="formLabel">
                            <Tooltip titlet="Standard">Stand. AWP</Tooltip>
                          </label>
                        </div>
                        <div className="input-group input-amount">
                          <InputNumber
                            className="formControl"
                            placeholder="0.00"
                            min={0}
                            formatter={(value) => amountFormatter(value)}
                            parser={(value) => amountParser(value)}
                            precision={2}
                          />
                          <label className="formLabel">Unit Cost</label>
                        </div>
                        <div className="input-group input-percent float-right">
                          <InputNumber
                            className="formControl"
                            placeholder="Surcharge"
                            min={0}
                            max={100}
                            formatter={(value) => percentFormatter(value)}
                            parser={(value) => percentParser(value)}
                            onChange={onChange}
                          />
                          <label className="formLabel">Surcharge</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-group input-amount">
                          <InputNumber
                            className="formControl"
                            placeholder="0.00"
                            min={0}
                            formatter={(value) => amountFormatter(value)}
                            parser={(value) => amountParser(value)}
                            precision={2}
                          />
                          <label className="formLabel">ACQ Cost</label>
                        </div>
                        <div className="input-group input-amount">
                          <InputNumber
                            className="formControl"
                            placeholder="0.00"
                            min={0}
                            formatter={(value) => amountFormatter(value)}
                            parser={(value) => amountParser(value)}
                            precision={2}
                          />
                          <label className="formLabel">Unit Cost</label>
                        </div>
                        <div className="input-group input-group-check">
                          <Checkbox>Auto update</Checkbox>
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="input-group input-amount">
                          <InputNumber
                            className="formControl"
                            placeholder="0.00"
                            min={0}
                            formatter={(value) => amountFormatter(value)}
                            parser={(value) => amountParser(value)}
                            precision={2}
                          />
                          <label className="formLabel">
                            <Tooltip titlet="Standard">Stand.</Tooltip> Cost
                          </label>
                        </div>
                        <div className="input-group input-amount">
                          <InputNumber
                            className="formControl"
                            placeholder="0.00"
                            min={0}
                            formatter={(value) => amountFormatter(value)}
                            parser={(value) => amountParser(value)}
                            precision={2}
                          />
                          <label className="formLabel">Unit Cost</label>
                        </div>
                        <div className="input-group input-percent float-right">
                          <InputNumber
                            className="formControl"
                            placeholder="Surcharge"
                            min={0}
                            max={100}
                            formatter={(value) => percentFormatter(value)}
                            parser={(value) => percentParser(value)}
                            onChange={onChange}
                          />
                          <label className="formLabel">Surcharge</label>
                        </div>
                      </div>
                      {/* <div className="col-12">
                        <div className="input-group input-percent float-right">
                          <InputNumber
                            className="formControl"
                            placeholder="Group Surcharge"
                            min={0}
                            max={100}
                            formatter={(value) => percentFormatter(value)}
                            parser={(value) => percentParser(value)}
                            onChange={onChange}
                          />
                          <label className="formLabel">Group Surcharge</label>
                        </div>
                      </div> */}
                      <div className="col-12 my-3 d-none">
                        <div className="input-group input-percent">
                          <InputNumber
                            className="formControl"
                            placeholder="Surcharge"
                            min={0}
                            max={100}
                            formatter={(value) => percentFormatter(value)}
                            parser={(value) => percentParser(value)}
                            onChange={onChange}
                          />
                          <label className="formLabel">Ins. Margin</label>
                        </div>
                        <div className="input-group input-amount">
                          <InputNumber
                            className="formControl"
                            placeholder="0.00"
                            min={0}
                            formatter={(value) => amountFormatter(value)}
                            parser={(value) => amountParser(value)}
                            precision={2}
                          />
                          <label className="formLabel">Ins. Disp Fee</label>
                        </div>
                        <div className="input-group input-amount float-right">
                          <InputNumber
                            className="formControl"
                            placeholder="0.00"
                            min={0}
                            formatter={(value) => amountFormatter(value)}
                            parser={(value) => amountParser(value)}
                            precision={2}
                          />
                          <label className="formLabel">Ins. Unit Total</label>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="d-flex justify-content-end">
                    <div className="input-group input-sm">
                      <Input className="formControl" />
                      <label className="formLabel tt-none">RxN PG #</label>
                    </div>
                    <div className="input-group input-sm">
                      <Input className="formControl" />
                      <label className="formLabel tt-none">RxN Ing #</label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <section className="product-thumbnail">
                    <div className="img-wrapper">
                      <img src={ThumbnailImg} />
                    </div>
                    <div className="row no-gutters mt-2">
                      <div className="col-lg-4">
                        <div className="input-group">
                          <Input className="formControl" />
                          <label className="formLabel">Shape</label>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <Input className="formControl" />
                          <label className="formLabel">Imprint</label>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <Input className="formControl" />
                          <label className="formLabel">Color 1</label>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <Input className="formControl" />
                          <label className="formLabel">Color 2</label>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <Input className="formControl" />
                          <label className="formLabel">Side 1</label>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <Input className="formControl" />
                          <label className="formLabel">Side 2</label>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <section className="bg-primary pr-4">
                  <div className="section-title d-flex align-items-center mb-2">
                    <h6>Auxillary Labels</h6>
                    <div className="d-flex ml-auto">
                      <Button className="btn btn-icon btn-sm btn-info">
                        <span></span>
                      </Button>
                      <Button className="btn btn-icon btn-sm btn-add">
                        <span></span>
                      </Button>
                    </div>
                  </div>
                  <div className="input-block w-100">
                    <div className="input-group width-100 mb-1 mt-0">
                      <Select className="formControl" showSearch placeholder="Code"></Select>
                    </div>
                    <div className="input-group width-100 mb-1 mt-0">
                      <Select className="formControl" showSearch placeholder="Code"></Select>
                    </div>
                    <div className="input-group width-100 mb-1 mt-0">
                      <Select className="formControl" showSearch placeholder="Code"></Select>
                    </div>
                    <div className="input-group width-100 mb-1 mt-0">
                      <Select className="formControl" showSearch placeholder="Code"></Select>
                    </div>
                  </div>
                </section>

                <hr></hr>
                <PatientPriceCodes />
                {/* <div className="row">
                  <div className="col-12">
                    <section className="m-0 p-0 border-0">
                      <div className="product-pricing-checks">
                        <Checkbox>Auto Fill</Checkbox>
                        <Checkbox>Show cost</Checkbox>
                        <Checkbox>Show Profit</Checkbox>
                        <Checkbox>Preffered</Checkbox>
                        <Checkbox>Dispense First</Checkbox>
                        <Checkbox>Black Box</Checkbox>
                        <Checkbox>HR Med</Checkbox>
                        <Checkbox>FSA Item</Checkbox>
                      </div>
                    </section>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-12">
              
            </div> */}
          </div>
        </section>
      </Modal>
    </>
  );
}
